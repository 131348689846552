import React, { useState } from 'react'
import Container from '@material-ui/core/Container';
import { makeStyles, Card, CardContent, Typography, Grid, FormGroup, TextField, Box, Button, LinearProgress } from '@material-ui/core';
import { Alert, AlertTitle } from "@material-ui/lab";
import { When } from 'react-if';
import { axios, setAuth } from '../../../../store/_api';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useAdminStore } from '../../../../store/use-store';

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    form: {
        "& > div > div": {
            marginTop: theme.spacing(3)
        }
    },
    cardContent: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
    },
    "@global": {
        "body": {
            background: theme.palette.grey[300]
        }
    }
}))

function AdminAccountLogin({ setLoggedIn }) {
    const styles = useStyles();

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [hasError, setHasError] = useState(undefined)
    const history = useHistory();
    const adminStore = useAdminStore();

    function handleSubmission(event) {
        event.preventDefault();
        setIsLoading(true);

        axios.post("system/auth/login", {
            username: username,
            password: password
        }).then(resp => {
            if (resp.data.status) {
                setAuth(resp.data.data.id_token);
                adminStore.userProfile = resp.data.data.profile
                setLoggedIn(true);
                history.push("/admin/");
            } else {
                setHasError(<>
                    <AlertTitle>{resp.data.error.title} [{resp.data.error.error_code}]</AlertTitle>
                    <div><p>{resp.data.error.description}</p></div>
                </>)
            }
        }).catch(resp => {
            if (resp.response && resp.response.data && resp.response.data.error) {
                setHasError(<>
                    <AlertTitle>{resp.response.data.error.title} [{resp.response.data.error.error_code}]</AlertTitle>
                    <div><p>{resp.response.data.error.description}</p></div>
                </>)
            } else {
                console.error(resp);
                console.log(JSON.stringify(resp));
                setHasError(<>
                    <AlertTitle>Error happened</AlertTitle>
                    <div>
                        <p>{resp.message}</p>
                        <p><small>Because it's a browser(/network) related error, the error has been emitted to the console.</small></p>
                    </div>
                </>)
            }

        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <Container maxWidth="lg" fixed>
            <Grid container className={styles.container}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <When condition={isLoading}>
                            <LinearProgress />
                        </When>
                        <CardContent className={styles.cardContent}>
                            <Typography variant="h5">Administrator Login</Typography>
                            <When condition={!!hasError} variant="filled">
                                <Box marginY={3}>
                                    <Alert onClose={() => setHasError(undefined)} severity="error" variant="filled">
                                        {hasError}
                                    </Alert>
                                </Box>
                            </When>
                            <form className={styles.form} onSubmit={handleSubmission}>
                                <Box marginY={5}>
                                    <FormGroup>
                                        <TextField label="Username" onChange={(e) => setUsername(e.target.value)} defaultValue={username} disabled={isLoading} />
                                    </FormGroup>
                                    <FormGroup>
                                        <TextField type="password" label="Password" onChange={(e) => setPassword(e.target.value)} defaultValue={password} disabled={isLoading} />
                                    </FormGroup>
                                </Box>
                                <Box style={{ textAlign: "right" }}>
                                    <Button variant="contained" color="primary" type="submit" disabled={isLoading}>Login</Button>
                                </Box>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default observer(AdminAccountLogin)
