import { decorate, observable, action } from "mobx";

import { axios } from "./_api";
export default class BannerStore {
    error = {};

    item = [];
    isLoading = true;

    fetch() {
        this.isLoading = true;

        axios.get("manage/beranda").then(data => {
            this.isLoading = false;
            if (data.data.status) {
                this.item = data.data.data;
            } else {
                // error happened.
                this.error = {
                    title: data.data.error.title,
                    description: data.data.error.description
                }
            }
        }).catch(err=>{
            console.log(err);
            this.isLoading = false;
            this.error = {
                title: "Terjadi masalah pada saat mengontak server. (HTTP)",
                description: "Mohon cek kembali koneksi anda. Jika masalah terus berlanjut, mohon hubungi developer."
            }
        });
    }
}

decorate(BannerStore, {
    item: observable,
    isLoading: observable,
    error: observable,
    fetch: action
})