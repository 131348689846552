import React, { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { clearAuth } from '../../../../store/_api'

function AdminLogout({ setLoggedIn }) {

    useEffect(() => {
        clearAuth();
        setLoggedIn(false);
        return () => { }
    }, [setLoggedIn])
    return (
        <center>
            <Box marginY={20}>
                <Typography>You've been logged out.</Typography>
            </Box>
        </center>
    )
}

export default AdminLogout
