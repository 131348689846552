// @ts-check
import { MobXProviderContext } from 'mobx-react'
import React from 'react'

// eslint-disable-next-line
import AdminStore from "./adminStore";

export function useStores() {
    return React.useContext(MobXProviderContext)
}

/**
 * Use Admin Store
 * @return {AdminStore} Admin Stores
 */
export function useAdminStore() {
    const { adminStore } = useStores();
    return adminStore;
}