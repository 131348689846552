import React from 'react'
import Typography from "@material-ui/core/Typography";
import { Link } from 'react-router-dom';
import { makeStyles, Paper } from '@material-ui/core'

let coverHeight = 200;

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.grey[300],
        flexDirection: "row-reverse",
        display: "flex",
        border: "unset",
        boxShadow: "unset",
        textDecoration: "none",
        overflow: "hidden",
        width: "100%",
        // buat yang di HP:
        [theme.breakpoints.down('sm')]: {
            background: `linear-gradient(90deg, ${theme.palette.primary.main} 70%, white)`,
            flexDirection: "column-reverse",
        },


        "& .content": {
            padding: theme.spacing(4),
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            "&>div:nth-child(1)": {
                flexGrow: 1
            },
            "&>div:nth-child(2) p": {
                [theme.breakpoints.down('sm')]: {
                    fontSize: "0.6rem",
                    marginTop: theme.spacing(1)
                }
            },
        },
        "& .content-thumbnail": {
            minHeight: coverHeight,
            minWidth: (16 / 9 * coverHeight),
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            [theme.breakpoints.down('sm')]: {
                minHeight: (coverHeight*0.9),
                minWidth: (4 / 3 * (coverHeight*0.9)),
            }
        },
    }
}))
function BeritaCard({ title, children, linkTo, image, date }) {
    const styles = useStyles();

    return (
        <Paper className={styles.root} component={Link} to={linkTo}>
            <div className="content">
                <div>
                    <Typography variant="h5">{title}</Typography>
                    <Typography>{children}</Typography>
                </div>
                <div>
                    <Typography>{date}</Typography>
                </div>
            </div>
            <div className="content-thumbnail" style={{ backgroundImage: `url(${image})` }}>&nbsp;</div>
        </Paper>
    )
}

export default BeritaCard
