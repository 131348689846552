import React from 'react'
import { makeStyles, Button, Typography, Box } from '@material-ui/core'
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: "flex",
        color: theme.palette.text.primary,
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "center",
        '&:before': {
            content: '" "',
            position: 'absolute',
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            background: "linear-gradient(270deg, #DFF2E1 0%, #C0EBC4 48.44%, #A3E7A9 100%)",
            opacity: 0.6,
            zIndex: 0
        },

        flexDirection: "row",

        [theme.breakpoints.down('sm')]: {
            height: "100%",
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: "column",
            minHeight: 350,
            justifyItems: "flex-end",

        },
        [theme.breakpoints.up('lg')]: {
            minHeight: 400,
        },
    },
    contentBox: {
        flexBasis: "60%",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        zoom: 0.8,
        zIndex: 10,
        textAlign: "left",
        alignSelf:"flex-start",
        [theme.breakpoints.up('md')]: {
            flexBasis: "unset",
            zoom: 1,
            textAlign: "left"
        }
    },
    linkButton: {
        borderRadius: "3rem",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        backgroundColor: theme.palette.text.primary,
        color: "white"
    }
}))

function CarouselItemBerita({ image, title, description, link, cta = "Baca Berita" }) {
    const style = useStyles();

    return (
        <div className={style.root} style={{ backgroundImage: `url(${image})` }}>
            <div className={style.contentBox}>
                <Box marginY={1}>
                    <Typography variant="h6">{title}</Typography>
                </Box>
                <Box marginY={1}>
                    <Typography component="div">{description}</Typography>
                </Box>
                <Box marginY={1}>
                    <Button className={style.linkButton} component={Link} to={link} variant="contained" disableElevation>{cta}</Button>
                </Box>
            </div>
        </div>
    )
}

export default CarouselItemBerita
