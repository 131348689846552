import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import NavbarMenu from "./menu";
import { NavLink } from 'react-router-dom';

const logo = require("../../assets/logo.png");

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    toolbar: {
        alignItems: 'flex-center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flexWrap: "wrap",
        [theme.breakpoints.down('sm')]: {
            alignItems: "flex-start",
            "&>div": {
                flexBasis: "100%",
                textAlign: "left"
            },
            "&>div:nth-child(1)": {
                marginBottom:  theme.spacing(2),
            }
        }
    },
    logo: {
        height: "8rem",
        [theme.breakpoints.down('md')]: {
            height: "4rem",
        }
    },
    appBar: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        boxShadow: "unset",
        "&.shouldTransparent": {
            backgroundColor: "transparent !important"
        }
    },

    menuBar: {
        flexGrow: 1,
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        "&>a":{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),

            [theme.breakpoints.down('sm')]: {
                marginLeft: "unset",
                marginRight: "unset",
            }
        }
    }
}));

function Navbar({ shouldTransparent=false }) {
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <AppBar position="static" className={styles.appBar + (shouldTransparent? " shouldTransparent": "")}>
                <Toolbar className={styles.toolbar}>
                        <div>
                            <NavLink to="/"><img src={logo} alt="Puskesmas Ciumbuleuit Bandung" className={styles.logo} /></NavLink>
                        </div>
                        <div className={styles.menuBar}>
                            <NavbarMenu component={NavLink} to="/" exact>Beranda</NavbarMenu>
                            <NavbarMenu component={NavLink} to="/berita">Berita</NavbarMenu>
                            <NavbarMenu component={NavLink} to="/profil">Profil</NavbarMenu>
                            <NavbarMenu component={NavLink} to="/pelayanan">Pelayanan</NavbarMenu>
                            <NavbarMenu component={NavLink} to="/kegiatan">Kegiatan</NavbarMenu>
                        </div>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Navbar
