import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { makeStyles } from '@material-ui/core/styles';
import CarouselItemBerita from './carousel-item-berita';
import { Typography, Container, CircularProgress, Box, useTheme, useMediaQuery } from '@material-ui/core';
import RegistrasiOffline from './sec-reg-offline';
import Footer from '../../footer/footer';
import Row from '../../grid-componoents/row';
import Col from '../../grid-componoents/col';
import FasilitasCard from './fasilitas-card';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/use-store';
import { If, Then, Else, Unless } from 'react-if';
import Buled from '../../buled/buled';
import RegisterOnline from './register-online';
import { TwitterTimelineEmbed } from "react-twitter-embed";

let imgBgHome = require("../../../assets/home/header-bg.svg")

const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 465 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const useStyles = makeStyles((theme) => ({
    carouselContainer: {
        backgroundColor: "#84DB8C",
        borderRadius: theme.spacing(3),
        margin: theme.spacing(1),
        overflow: "hidden",

        [theme.breakpoints.up('md')]: {
            borderRadius: "unset"
        },

        "& .react-multi-carousel-dot-list": {
            marginBottom: theme.spacing(1)
        }
    },
    fasilitasCard: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            margin: 0,
        }
    },
    titleFasilitas: {
        textAlign: "center",
        marginBottom: theme.spacing(3)
    },
    fasilitasCardContainer: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(7),
        [theme.breakpoints.down('md')]: {
            padding: 0,
            paddingBottom: theme.spacing(7),
        }
    },
    embedder: {
        display: "flex",
        height: "100%",
        "& > div": {
            padding: theme.spacing(1),
            flexGrow: 1,
        }
    },
    "@global": {
        [theme.breakpoints.up('md')]: {
            "body": {
                backgroundImage: `url(${imgBgHome})`,
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center -3rem"
            }
        }
    }
}))

function Beranda() {
    const style = useStyles();

    const { fasilitas, banner } = useStores();
    const theme = useTheme();
    const [openRegisterModal, setOpenRegisterModal] = useState(false)
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {
        document.title = "Beranda | Puskesmas Ciumbuleuit";

        if (fasilitas.item.length === 0) {
            fasilitas.fetchFasilitas();
        }
        if (banner.item.length === 0) {
            banner.fetch();
        }
        return () => { };
    }, [fasilitas, banner])

    return (
        <>
            <div className="container-fluid">
                <Row className="center-md">
                    <Col size={{ xl: 5, md: 7, xs: 12 }}>
                        <div className={style.carouselContainer} elevation={0}>
                            <If condition={banner.isLoading}>
                                <Then>
                                    <Box margin={2}>
                                        <CircularProgress />
                                    </Box>
                                </Then>
                                <Else>
                                    <Carousel responsive={responsive} showDots infinite autoPlay={false} arrows={false}>
                                        {banner.item.map((item, i) => <If condition={item.type === "berita"} key={i}>
                                            <Then>
                                                <CarouselItemBerita
                                                    title={item.title}
                                                    description={item.description}
                                                    image={item.images[0]}
                                                    link={"/berita/" + item._id}
                                                    cta="Baca Berita"
                                                />
                                            </Then>
                                            <Else>
                                                <CarouselItemBerita
                                                    title={item.name}
                                                    description={<div dangerouslySetInnerHTML={{ __html: item.description }} />}
                                                    image={item.images[item.images.length - 1]}
                                                    link={"/berita/" + item.news_link}
                                                    cta="Lihat Kegiatan"
                                                />
                                            </Else>
                                        </If>)}
                                    </Carousel>
                                </Else>
                            </If>
                        </div>
                    </Col>
                    <Unless condition={fullScreen}>
                        <Col size={{ xl: 5, md: 3, xs: 0 }}>
                            <div className={style.embedder}>
                                <TwitterTimelineEmbed
                                    sourceType="profile"
                                    screenName="bandung_dinkes"
                                    options={{ height: "100%", width: "100%" }}
                                    lang="id"
                                    placeholder={<Box margin={2}>
                                        <CircularProgress />
                                    </Box>}
                                />
                            </div>
                        </Col>
                    </Unless>
                </Row>
            </div>

            <section style={{ marginTop: "5rem" }}>
                <RegistrasiOffline onRegisterOnlineClick={() => setOpenRegisterModal(true)} />
                <RegisterOnline openModal={openRegisterModal} triggerClose={() => setOpenRegisterModal(false)} />
            </section>

            <Container style={{ overflow: "hidden" }}>
                <div className={style.fasilitasCard + " container-fluid"}>
                    <Typography variant="h5" className={style.titleFasilitas}><b>Fasilitas</b></Typography>
                    <Buled xs={{ x: "-6rem", y: "15rem", size: "14rem" }} md={{ x: "90%", y: "-2rem", size: "10rem" }} style={{ opacity: 0.6 }} />
                    <Buled xs={{ x: "80%", y: "45rem", size: "14rem" }} md={{ x: "-3rem", y: "17rem", size: "13rem" }} style={{ opacity: 0.6 }} rotate="147.33deg" />
                    <Buled xs={{ x: "-3rem", y: "86rem", size: "9rem" }} md={{ x: "60%", y: "30rem", size: "20rem" }} style={{ opacity: 0.8 }} />
                    <Buled xs={{ x: "70%", y: "130rem", size: "17rem" }} md={{ x: "-4rem", y: "60rem", size: "20rem" }} style={{ opacity: 0.6 }} rotate="85deg" />

                    <div>
                        <Row className="center-xs">
                            {fasilitas.item.map((data, i) => <Col size={{ sm: 12, md: 3, lg: 4 }} key={i}>
                                <div className={style.fasilitasCardContainer}>
                                    <FasilitasCard title={data.name}
                                        jadwal={<div dangerouslySetInnerHTML={{ __html: data.schedule }} />}
                                        image={(data.images || [null])[0]}
                                        linkTo={"/berita/" + data.news_link?._id + "?with-icon"}>
                                        <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                    </FasilitasCard>
                                </div>
                            </Col>)}
                        </Row>
                    </div>
                </div>
            </Container>

            <Footer />
        </>
    )
}

export default observer(Beranda);
