export function translateAsText(data, rule, globalRule) {
    let displayValues = data;

    if (rule.type === "select") {
        // if the rule is select and it has choices, then:
        if (rule.choices) {
            if (rule.choices.hasOwnProperty(data)) {
                // check if there's choice with key "data".
                displayValues = rule.choices[data];
            }
        } else if (rule.link) {
            if (globalRule.hasOwnProperty(rule.link)) {
                displayValues = representItemToText(data, globalRule[rule.link])
            } else {
                displayValues = JSON.stringify(data);
            }
        }
    }

    return displayValues;
}

export function representItemToText(data, rule) {
    let displayAs = rule.listing;
    let display = [];
    if (!data) {
        return "(tidak ada // null)";
    }
    displayAs.forEach(element => {
        if (element === "_id") {
            display.push("(" + data?._id + ")");
        } else {
            display.push(translateAsText(data[element] || "null", rule.fields[element]));
        }
    });

    return display.join(", ");
}

export function buildDefaultInput(rule) {
    let def = {};
    Object.keys(rule.fields).forEach(el => {
        def[el] = "";
    });

    return def;
}

export function exportInputForRequest(rule, data) {
    let filteredData = {};
    Object.keys(rule.fields).forEach(el => {
        if (!rule.fields[el].editable) {
            return;
        }

        if (rule.fields[el].required && !data[el]) {
            throw new Error(`${rule.fields[el].title} wajib diisi!`)
        }

        filteredData[el] = data[el];
    })

    return filteredData;
}