import React from 'react'
import { Drawer, makeStyles, useTheme, IconButton, Divider } from '@material-ui/core'
import { ChevronRight, ChevronLeft } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    drawerPaper: ({ width }) => ({
        width: width
    }),
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
}))

function AdminSidebar({ width = 340, children }) {
    const styles = useStyles({ width: width });
    const theme = useTheme();
    return (
        <Drawer
            classes={{
                paper: styles.drawerPaper,
            }}
            variant="permanent" open>
            <div>
                <div className={styles.toolbar}>
                    <IconButton>
                        {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                    </IconButton>
                </div>
                <Divider />
                {children}
            </div>
        </Drawer>
    )
}

export default AdminSidebar
