import React, { useEffect } from 'react'
import { makeStyles, Container, CircularProgress } from '@material-ui/core'
import KartuInfoHalaman from '../../kartu-info-halaman/kartu-info-halaman'
import KegiatanCard from './kegiatan-card'
import Row from '../../grid-componoents/row'
import Col from '../../grid-componoents/col'
import Footer from '../../footer/footer'
import { useStores } from '../../../store/use-store'
import { observer } from 'mobx-react'
import { If, Then, Else } from 'react-if'

const useStyle = makeStyles(theme => ({
    infoHalaman: {
        padding: theme.spacing(2)
    },
    listingKegiatan: {
        display: "flex",

        "& div.row>div": {
            padding: theme.spacing(2)
        },
        "& div.row": {
            width: "100%",
            marginLeft: "unset",
            marginRight: "unset",
        }
    }
}))

function KegiatanPage() {
    const styles = useStyle();

    const { kegiatan } = useStores();

    document.title = "Kegiatan | Puskesmas Ciumbuleuit";

    useEffect(() => {
        if (kegiatan.item.length === 0) {
            kegiatan.fetch();
        }
        return () => { };
    }, [kegiatan])
    return (
        <div>
            <Container>
                <section className={styles.infoHalaman}>
                    <KartuInfoHalaman title="Kegiatan">
                        Puskemas Ciumbuleuit memiliki beberapa kegiatan internal dan eksternal. Kegiatan-kegiatan ini dilakukan secara rutin dengan periode kegiatan mulai dari kegiatan harian sampai dengan kegiatan tahunan
                    </KartuInfoHalaman>
                </section>
                <section>

                    <div className={styles.listingKegiatan}>
                        <Row className="center-xs">
                            <If condition={kegiatan.isLoading}>
                                <Then>
                                    <Col size={12}>
                                        <CircularProgress />
                                    </Col>
                                </Then>
                                <Else>
                                    {kegiatan.item.map((data, i) =>
                                        <Col size={{ xs: 12, md: 4 }} key={i}>
                                            <KegiatanCard
                                                title={data.name}
                                                jadwal={<div dangerouslySetInnerHTML={{ __html: data.schedule }} />}
                                                image={((data.news_link.images.length > 0) ? data.news_link.images[data.news_link.images.length - 1] : null)}
                                                linkTo={"/berita/" + data.news_link._id}
                                            >
                                                <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                            </KegiatanCard>
                                        </Col>
                                    )}
                                </Else>
                            </If>

                        </Row>
                    </div>
                </section>
            </Container>

            <Footer />
        </div>
    )
}

export default observer(KegiatanPage)
