import { Box, Button, Grid, IconButton, LinearProgress, Link as TextLink, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { AddCircle, Delete, Edit } from '@material-ui/icons'
import { Alert, AlertTitle } from '@material-ui/lab'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { When } from 'react-if'
import { Link } from 'react-router-dom'
import { useAdminStore } from '../../../../store/use-store'
import AdminManageDelete from './delete'
import { translateAsText } from './utils/translate'
const useStyles = makeStyles((theme) => ({
    tablePaper: {
        // background: theme.palette.grey['300']
    },
    "@global": {
        "body": {
            background: theme.palette.grey[200]
        }
    }
}))

function AdminManageLister({ rule, globalRule }) {

    const styles = useStyles();
    const adminStore = useAdminStore();

    const [tryDelete, setTryDelete] = useState(undefined)
    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(undefined)
    useEffect(() => {
        setIsLoading(true);
        adminStore.fetchItems(rule.endpoint).then(e => {
            setIsLoading(false);
        }).catch((resp) => {
            if (resp.response && resp.response.data && resp.response.data.error) {
                setHasError(<>
                    <AlertTitle>{resp.response.data.error.title} [{resp.response.data.error.error_code}]</AlertTitle>
                    <div><p>{resp.response.data.error.description}</p></div>
                </>)
            } else {
                console.error(resp);
                console.log(JSON.stringify(resp));
                setHasError(<>
                    <AlertTitle>Error happened</AlertTitle>
                    <div>
                        <p>{resp.message}</p>
                        <p><small>Because it's a browser(/network) related error, the error has been emitted to the console.</small></p>
                    </div>
                </>)
            }

        });
        return () => { }
    }, [rule.endpoint, adminStore]);

    let fields = rule.fields;
    let headers = rule.listing;
    let items = adminStore.getItems(rule.endpoint);
    return (
        <div>
            <Grid container alignItems="center">
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Atur</Typography>
                    <Typography variant="h5">{rule.title}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box textAlign="right">
                        <Button variant="contained" color="primary" component={Link} to={"/admin/manage/" + rule.endpoint + "/new"} startIcon={<AddCircle />}> Buat</Button>
                    </Box>
                </Grid>
            </Grid>

            <When condition={!!hasError} variant="filled">
                <Box marginY={3}>
                    <Alert onClose={() => setHasError(undefined)} severity="error" variant="filled">
                        {hasError}
                    </Alert>
                </Box>
            </When>

            <Box marginY={3}>
                <TableContainer component={Paper} className={styles.tablePaper}>
                    <When condition={isLoading}>
                        <LinearProgress />
                    </When>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headers.map((name) => <TableCell key={name}>{fields[name].title || name}</TableCell>)}
                                <TableCell style={{ width: 110 }} align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((el) => <TableRow key={el._id}>
                                {headers.map((name) => {
                                    let frule = fields[name];
                                    let displayValue = translateAsText(el[name], frule, globalRule);

                                    if (frule.type === "select" && frule.link && el[name]?._id) {
                                        displayValue = <TextLink component={Link} to={"/admin/manage/" + globalRule[rule.endpoint].endpoint + "/" + el[name]?._id}>{displayValue}</TextLink>;
                                    }

                                    return <TableCell key={el._id + name}>
                                        {displayValue}
                                    </TableCell>
                                })}
                                <TableCell align="center" style={{ padding: 0 }}>
                                    <IconButton component={Link} to={"/admin/manage/" + rule.endpoint + "/" + el._id}><Edit /></IconButton>
                                    <IconButton onClick={() => setTryDelete(el)}><Delete /></IconButton>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <When condition={!!tryDelete}>
                <AdminManageDelete data={tryDelete} rule={rule} globalRule={globalRule} onRequestClose={() => setTryDelete(undefined)} onFinished={() => setTryDelete(undefined)} />
            </When>
        </div>
    )
}

export default observer(AdminManageLister)
