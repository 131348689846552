// @ts-check
import { Box, CircularProgress, Container, Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { Book, ExitToApp } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { Link, NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAdminStore } from '../../../store/use-store';
import { axios } from "../../../store/_api";
import AdminNavbar from '../../admin-navbar/admin-navbar';
import AdminSidebar from '../../admin-navbar/admin-sidebar';
import AdminAccountLogin from './account/login';
import AdminLogout from './account/logout';
import AdminManageIndex from './manage';
import rules from "./rule";
import AdminWelcome from './welcome';

const sidebarWidth = 340;

const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: sidebarWidth,
        "&:before": {
            content: ' " " ',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
    }
}))

function AdminIndex() {
    const match = useRouteMatch("/admin");
    const styles = useStyles();

    const [loadFinish, setLoadFinish] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const adminStore = useAdminStore();

    let realDocTitle = "Admin Panel";
    if (adminStore.barTitle && adminStore.barTitle.length > 0) {
        realDocTitle += ": " + adminStore.barTitle;
    }
    useEffect(() => {
        document.title = realDocTitle + " | Puskesmas Ciumbuleuit Bandung";
        return () => { }
    }, [realDocTitle])

    useEffect(() => {
        axios.get("system/user/me").then((e) => {
            if (e.data.status) {
                adminStore.userProfile = e.data.data.profile;
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        }).catch((e) => {
            setIsLoggedIn(false);
        }).finally(() => {
            setLoadFinish(true);
        })
        return () => { }
    }, [setIsLoggedIn, setLoadFinish, adminStore.userProfile])

    return (
        <Switch>
            <If condition={!loadFinish}>
                <Then>
                    <Box style={{ textAlign: "center", paddingTop: 30 }}>
                        <CircularProgress color="secondary" />
                    </Box>
                </Then>
                <Else>
                    <Route path={match.path + "/account/logout"}><AdminLogout setLoggedIn={setIsLoggedIn} /></Route>
                    <If condition={isLoggedIn}>
                        <Then>
                            <Route path={match.path + "/"}>
                                <AdminNavbar title={realDocTitle} />
                                <AdminSidebar width={sidebarWidth}>
                                    <List>
                                        {Object.values(rules).map((el) => {
                                            if (el.hide) {
                                                return null;
                                            }
                                            return <ListItem button key={el.endpoint} component={NavLink} activeClassName="Mui-selected" to={"/admin/manage/" + el.endpoint}>
                                                <ListItemIcon>
                                                    <If condition={!!el.icon}>
                                                        <Then>{el.icon}</Then>
                                                        <Else><Book /></Else>
                                                    </If>
                                                </ListItemIcon>
                                                <ListItemText>{el.title}</ListItemText>
                                            </ListItem>
                                        })}
                                    </List>
                                    <Divider />
                                    <List>
                                        <ListItem button component={Link} to="/admin/account/logout">
                                            <ListItemIcon><ExitToApp /></ListItemIcon>
                                            <ListItemText>Logout</ListItemText>
                                        </ListItem>
                                    </List>
                                </AdminSidebar>
                                <div className={styles.container}>
                                    <Container maxWidth="lg" fixed>
                                        <Route path={match.path} exact><AdminWelcome /></Route>
                                        <Route path={match.path + "/manage"}><AdminManageIndex /></Route>
                                    </Container>
                                </div>
                            </Route>
                        </Then>
                        <Else>
                            <Route path={match.path + "/account/login"}><AdminAccountLogin setLoggedIn={setIsLoggedIn} /></Route>
                            <Redirect to={match.path + "/account/login"} />
                        </Else>
                    </If>
                </Else>
            </If>
        </Switch>
    )
}

export default observer(AdminIndex)