import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: ({ responsive, rotate }) => ({
        position: "relative",
        width: 0,
        height: 0,
        overflow: "visible",
        zIndex: -1,

        ...(responsive.xs ? {
            [theme.breakpoints.down('xs')]: {
                top: responsive.xs.y,
                left: responsive.xs.x,
            }
        } : {}),
        ...(responsive.sm ? {
            [theme.breakpoints.up('sm')]: {
                top: responsive.sm.y,
                left: responsive.sm.x,
            }
        } : {}),
        ...(responsive.md ? {
            [theme.breakpoints.up('md')]: {
                top: responsive.md.y,
                left: responsive.md.x,
            }
        } : {}),
        ...(responsive.lg ? {
            [theme.breakpoints.up('lg')]: {
                top: responsive.lg.y,
                left: responsive.lg.x,
            }
        } : {}),
        ...(responsive.xl ? {
            [theme.breakpoints.up('xl')]: {
                top: responsive.xl.y,
                left: responsive.xl.x,
            }
        } : {}),

        "&::before": {
            content: ' " " ',
            background: "radial-gradient(55.18% 55.18% at 90.89% 57.86%, #F45858 0%, #EF9949 100%)",
            borderRadius: "50%",
            position: "absolute",
            transform: `rotate(${rotate})`,

            ...(responsive.xs ? {
                [theme.breakpoints.down('xs')]: {
                    width: responsive.xs.size,
                    height: responsive.xs.size,
                }
            } : {}),
            ...(responsive.sm ? {
                [theme.breakpoints.up('sm')]: {
                    width: responsive.sm.size,
                    height: responsive.sm.size,
                }
            } : {}),
            ...(responsive.md ? {
                [theme.breakpoints.up('md')]: {
                    width: responsive.md.size,
                    height: responsive.md.size,
                }
            } : {}),
            ...(responsive.lg ? {
                [theme.breakpoints.up('lg')]: {
                    width: responsive.lg.size,
                    height: responsive.lg.size,
                }
            } : {}),
            ...(responsive.xl ? {
                [theme.breakpoints.up('xl')]: {
                    width: responsive.xl.size,
                    height: responsive.xl.size,
                }
            } : {}),
        }
    })
}))

function Buled({ style, position = null, xs = null, sm = null, md = null, lg = null, xl = null, rotate = 0 }) {
    xs = xs || position;
    sm = sm || xs;
    md = md || sm;
    lg = lg || md;
    xl = xl || lg;

    const styles = useStyles({
        responsive: {
            xs: xs,
            sm: sm,
            md: md,
            lg: lg,
            xl: xl
        },
        rotate: rotate
    });
    return (
        <div className={styles.root} style={style} />
    )
}

export default Buled
