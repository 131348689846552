import React, { useEffect } from 'react'
import KartuInfoHalaman from '../../kartu-info-halaman/kartu-info-halaman'
import Row from '../../grid-componoents/row'
import Col from '../../grid-componoents/col'
import { Container, makeStyles, CircularProgress } from '@material-ui/core'
import PelayananCard from './pelayanan-card'
import Footer from '../../footer/footer'
import { useStores } from '../../../store/use-store'
import { observer } from 'mobx-react'
import { If, Then, Else } from 'react-if'

const useStyles = makeStyles(theme => ({
    infoHalaman: {
        padding: theme.spacing(2)
    },
    listingPelayanan: {
        display: "flex",

        "& div.row>div": {
            padding: theme.spacing(2)
        },
        "& div.row": {
            width: "100%",
            marginLeft: "unset",
            marginRight: "unset",
        }
    }
}));

function PelayananPage() {
    const styles = useStyles();
    const { pelayanan } = useStores();

    document.title = "Pelayanan | Puskesmas Ciumbuleuit";

    useEffect(() => {
        if (pelayanan.item.length === 0) {
            pelayanan.fetch();
        }
        return () => { };
    }, [pelayanan])
    return (<>
        <Container>
            <section className={styles.infoHalaman}>
                <KartuInfoHalaman title="Pelayanan">
                    Puskemas Ciumbuleuit menyediakan berbagai layanan kesehatan yang dapat digunakan oleh masyarakat. Pendaftaran sebagian besar layanan ini dapat dilakukan secara online melalui aplikasi Temen Online Puskemas Ciumbuleuit
                </KartuInfoHalaman>
            </section>
            <section className={styles.listingPelayanan}>
                <Row className="center-xs">
                    <If condition={pelayanan.isLoading}>
                        <Then>
                            <Col size={12}>
                                <CircularProgress />
                            </Col>
                        </Then>
                        <Else>
                            {pelayanan.item.map((data, i) => <Col size={{ md: 4, xs: 12 }} key={i}>
                                <PelayananCard
                                    title={data.name}
                                    image={(data.images || [null])[0]}
                                    jadwal={<div dangerouslySetInnerHTML={{ __html: data.schedule }} />}
                                    linkTo={"/pelayanan"}>
                                    <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                </PelayananCard>
                            </Col>)}
                        </Else>
                    </If>
                </Row>
            </section>
        </Container>
        <Footer />
    </>
    )
}

export default observer(PelayananPage);
