import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, Grid, Input, Select, MenuItem, InputLabel, Typography, makeStyles, DialogActions, Button, CircularProgress } from '@material-ui/core'
import { translateAsText } from './utils/translate';
import { When } from 'react-if';
import { useAdminStore } from '../../../../store/use-store';

const useStyle = makeStyles((theme) => ({
    gridItem: {
        marginBottom: theme.spacing(2)
    },
    progress: {
        margin: theme.spacing(3)
    }
}))

function AdminManageDelete({ data = {}, rule, globalRule, onRequestClose = () => { }, onFinished = () => { }, onError = () => { } }) {
    let fields = rule.fields;

    let styles = useStyle();
    const [isLoading, setIsLoading] = useState(false)

    let adminStore = useAdminStore();
    function handleDelete() {
        setIsLoading(true);
        adminStore.deleteItem(rule.endpoint, data).then((data) => {
            // done.
            setIsLoading(false);
            onFinished();
        }).catch(onError);
    }

    return (
        <>
            <When condition={isLoading}>
                <Dialog maxWidth="sm" open>
                    <DialogContent className={styles.progress}>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
            </When>
            <Dialog onClose={onRequestClose} maxWidth="md" open={!!data}>
                <DialogTitle>Apakah anda yakin untuk menghapus data ini?</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {Object.keys(fields).map((el) => {
                            if (fields[el].type === "textarea:rich") {
                                return <Grid className={styles.gridItem} key={el} item xs={6}>
                                    <InputLabel>{fields[el].title}</InputLabel>
                                    <Typography component="div" variant="body1" dangerouslySetInnerHTML={{ __html: data[el] }} />
                                </Grid>
                            }

                            if (fields[el].type === "select") {
                                if (fields[el].link) {
                                    return <Grid className={styles.gridItem} key={el} item xs={4}>
                                        <InputLabel>{fields[el].title}</InputLabel>
                                        <Select value={data[el]._id} readOnly fullWidth>
                                            <MenuItem value={data[el]._id}>{translateAsText(data[el], fields[el], globalRule)}</MenuItem>
                                        </Select>
                                    </Grid>
                                }

                                if (fields[el].choice) {
                                    return <Grid className={styles.gridItem} key={el} item xs={4}>
                                        <InputLabel>{fields[el].title}</InputLabel>
                                        <Select value={data[el]} readOnly fullWidth>
                                            <MenuItem value={data[el]}>{translateAsText(data[el], fields[el], globalRule)}</MenuItem>
                                        </Select>
                                    </Grid>
                                }
                            }

                            // datetime
                            return <Grid className={styles.gridItem} key={el} item xs={4}>
                                <InputLabel>{fields[el].title}</InputLabel>
                                <Input type={fields[el].type} value={data[el]} name={el} readOnly fullWidth />
                            </Grid>
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={onRequestClose}>Batal</Button>
                    <Button color="secondary" onClick={handleDelete}>Hapus</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AdminManageDelete
