import React from 'react'

import rule from "../rule";
import { useRouteMatch, Redirect, Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import AdminManageLister from './list';
import { observer } from 'mobx-react';
import AdminManageEditor from './editor';
function AdminManageIndex() {
    const match = useRouteMatch("/admin/manage/:endpoint");

    if (!rule.hasOwnProperty(match.params.endpoint)) {
        return <Redirect to={"/admin/"} />
    }
    let theRule = rule[match.params.endpoint];
    return (
        <Box marginY={4}>
            <Switch>
                <Route path={match.path} exact><AdminManageLister rule={theRule} globalRule={rule} /></Route>
                <Route path={match.path + "/new"} exact><AdminManageEditor rule={theRule} globalRule={rule} /></Route>
                <Route path={match.path + "/:id"}><AdminManageEditor rule={theRule} globalRule={rule} /></Route>
            </Switch>
        </Box>
    )
}

export default observer(AdminManageIndex)
