import React, { useEffect } from 'react'
import { Container, makeStyles, Paper, Typography, Box } from '@material-ui/core'
import { useStores } from '../../../../store/use-store'
import { observer } from 'mobx-react';
import { When, If, Then, Else } from 'react-if';
import Footer from '../../../footer/footer';
import { useLocation } from 'react-router-dom';

import queryString from "query-string";

const useStyles = makeStyles(theme => ({
    root: {

    },
    thumbnail: {
        minHeight: 300,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
    },
    gambars: {
        maxWidth: "100%",
        height: "auto"
    }
}))

function BeritaReader({ match }) {
    const location = useLocation();
    const { berita } = useStores();
    const styles = useStyles();

    document.title = berita.item.title + " | Berita | Puskesmas Ciumbuleuit";
    useEffect(() => {

        if (berita.selectedItem !== match.params.id || !berita.item) {
            berita.fetchItem(match.params.id);
        }
        return () => {
            berita.clearSelection();
        };
    }, [berita, match])

    let images = [...((berita.item || {}).images || [])];
    let qs = { ...queryString.parse(location.search) };
    let isWithIcon = qs.hasOwnProperty("with-icon");
    if (isWithIcon) {
        images.shift();
    }

    return (
        <>
            <Container style={{ minHeight: "80vh" }}>
                <When condition={berita.item && berita.item.images && berita.item.images.length > 0}>
                    <If condition={isWithIcon}>
                        <Then>
                            <img src={((berita.item || {}).images || [])[0]} alt="icon" style={{ height: "10rem", width: "auto" }} />
                        </Then>
                        <Else>
                            <Paper elevation={4} style={{ backgroundImage: `url('${berita.item && berita.item.images ? berita.item.images[0] : null}')` }} className={styles.thumbnail} />
                        </Else>
                    </If>
                </When>
                <Box marginY={4}>
                    <Typography variant="h3" component="h1">{berita.item.title}</Typography>
                </Box>
                <Box>
                    <Typography component="div">
                        <div dangerouslySetInnerHTML={{ __html: berita.item.body }} />
                    </Typography>
                </Box>
                <When condition={berita.item && berita.item.images && berita.item.images.length > 0}>
                    {images.map((img, i) => <Box key={i} marginY={3}>
                        <img src={img} alt={"Gambar " + i} className={styles.gambars} />
                    </Box>)}
                </When>
            </Container>
            <Footer />
        </>
    )
}

export default observer(BeritaReader);
