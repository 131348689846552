import { AssignmentIndOutlined, DescriptionOutlined, TableChartOutlined, ViewCarouselOutlined } from '@material-ui/icons'
import React from 'react'

export default {
    infocard: {
        endpoint: "infocard",
        title: "Pelayanan & Fasilitas",
        icon: <ViewCarouselOutlined />,
        allow: { c: true, r: true, u: true, d: true },
        extras: {
            editor: [
                ["notes",
                    { note: "Gambar pada bagian ini dapat diunggah pada menu berita. Gambar untuk latar kartu akan menggunakan gambar pertama, dan seluruh gambar akan ditampilkan pada halaman pembaca artikel." }
                ]
            ],
        },
        fields: {
            "_id": { title: "ID", type: "text", editable: false },
            "name": { title: "Judul", type: "text", editable: true, required: true },
            "type": { title: "Jenis Kartu", type: "select", editable: true, choices: ["pelayanan", "fasilitas"], required: true },
            "news_link": { title: "Link Informasi Lengkap (Berita)", type: "select", editable: true, link: "berita", required: true },
            "created_on": { title: "Dibuat Pada", type: "datetime", editable: false },
            "updated_on": { title: "Diperbaharui Pada", type: "datetime", editable: false },
            "description": { title: "Deskripsi", helper: (<>Biasanya berisi informasi singkat tentang kartu ini. <br /> Contoh: kesehatan Ibu dan Anak (KIA) Melayani Imunisasi BCG,TD,TT,Pentabio,MR/Campak,Polio Tetes ,IVP (Polio Suntik).</>), type: "textarea:rich", editable: true, required: true },
            "schedule": { title: "Jadwal", type: "textarea:rich", editable: true, required: true },
        },
        listing: ["_id", "name", "type", "created_on", "news_link"],
    },
    kegiatan: {
        endpoint: "kegiatan",
        title: "Kegiatan",
        icon: <ViewCarouselOutlined />,
        allow: { c: true, r: true, u: true, d: true },
        extras: {
            editor: [
                ["notes",
                    {
                        note: "Gambar pada bagian ini dapat diunggah pada menu berita. Gambar untuk latar kartu akan menggunakan gambar pertama, dan seluruh gambar akan ditampilkan pada halaman pembaca artikel."
                    }
                ]
            ],
        },
        fields: {
            "_id": { title: "ID", type: "text", editable: false, required: true },
            "name": { title: "Judul", type: "text", editable: true, required: true },

            "is_frequent": { title: "Apakah Sering?", type: "select", editable: true, choices: { 1: "Ya", 0: "Tidak" }, required: true },
            "news_link": { title: "Link Artikel", type: "select", editable: true, link: "berita", required: true },

            "created_on": { title: "Dibuat Pada", type: "datetime", editable: false },
            "updated_on": { title: "Diperbaharui Pada", type: "datetime", editable: false },

            "description": { title: "Deskripsi", type: "textarea:rich", editable: true, required: true },
            "schedule": { title: "Jadwal", type: "textarea:rich", editable: true, required: true },
        },
        listing: ["_id", "name", "schedule", "created_on"],
    },
    berita: {
        endpoint: "berita",
        title: "Berita",
        icon: <DescriptionOutlined />,
        allow: { c: true, r: true, u: true, d: true },
        extras: { editor: ["images"], },
        fields: {
            "_id": { title: "ID", type: "text", editable: false },
            "title": { title: "Title", type: "text", editable: true },

            "should_list": { title: "Tampilkan pada daftar berita", type: "select", editable: true, choices: { 1: "Ya", 0: "Tidak" }, required: true },

            "created_on": { title: "Dibuat Pada", type: "datetime", editable: false, required: false },
            "updated_on": { title: "Diperbaharui Pada", type: "datetime", editable: false, required: false },

            "description": { title: "Sinopsis Singkat Berita", type: "textarea", editable: true, required: true },
            "body": { title: "Isi Artikel", type: "textarea:rich", editable: true, required: true },
        },
        listing: ["_id", "title", "should_list", "created_on"],
    },
    staff: {
        endpoint: "staff",
        title: "Staff",
        icon: <TableChartOutlined />,
        allow: { c: true, r: true, u: true, d: true },
        extras: {},
        fields: {
            "_id": { title: "ID", type: "text", editable: false },
            "title": { title: "Judul", type: "text", editable: true, required: true },
            "asn_total": { title: "Jumlah ASN", type: "number", editable: true, required: true },
            "nonasn_total": { title: "Jumlah non-ASN", type: "number", editable: true, required: true },

            "created_on": { title: "Dibuat Pada", type: "datetime", editable: false },
            "updated_on": { title: "Diperbaharui Pada", type: "datetime", editable: false },
        },
        listing: ["_id", "title", "asn_total", "nonasn_total"],
    },
    user: {
        endpoint: "user",
        title: "Administator",
        icon: <AssignmentIndOutlined />,
        allow: { c: true, r: true, u: true, d: true },
        extras: {},
        fields: {
            "_id": { title: "ID", type: "text", editable: false },
            "username": { title: "Nama Pengguna", type: "text", editable: true, required: true },
            "email": { title: "Email", type: "text", editable: true, required: true },
            "password": { title: "Kata Sandi", type: "password", editable: true, required: false, nullable: true },

            "acl": { title: "Level Akses", type: "select", link: "acl", editable: true, required: true },
            "created_on": { title: "Dibuat Pada", type: "datetime", editable: false },
            "updated_on": { title: "Diperbaharui Pada", type: "datetime", editable: false },
            "deleted_on": { title: "Dihapus Pada", type: "datetime", editable: false },
        },
        listing: ["_id", "username", "email", "acl"],
    },
    acl: {
        endpoint: "acl",
        title: "Access Control List",
        allow: { c: false, r: false, u: false, d: false },
        extras: {},
        fields: {
            "_id": { title: "ID", type: "text", editable: false },
            "name": { title: "Level Akses", type: "text", editable: false },
            "created_on": { title: "Dibuat Pada", type: "datetime", editable: false },
            "updated_on": { title: "Diperbaharui Pada", type: "datetime", editable: false },
            "deleted_on": { title: "Dihapus Pada", type: "datetime", editable: false },
        },
        listing: ["_id", "name"],
        hide: true
    },
}