import { decorate, observable, action, computed } from "mobx";

import { axios } from "./_api";
export default class StaffStore {
    error = {};

    item = [];
    isLoading = true;

    get compiledStaffData() {
        let total = [0, 0, 0];
        this.item.forEach((val) => {
            total[0] += val.asn_total + val.nonasn_total;
            total[1] += val.asn_total;
            total[2] += val.nonasn_total;
        })
        return total;
    }

    fetch() {
        this.isLoading = true;

        axios.get("manage/staff").then(data => {
            this.isLoading = false;
            if (data.data.status) {
                this.item = data.data.data;
            } else {
                // error happened.
                this.error = {
                    title: data.data.error.title,
                    description: data.data.error.description
                }
            }
        }).catch(err => {
            console.log(err);
            this.isLoading = false;
            this.error = {
                title: "Terjadi masalah pada saat mengontak server. (HTTP)",
                description: "Mohon cek kembali koneksi anda. Jika masalah terus berlanjut, mohon hubungi developer."
            }
        });
    }
}

decorate(StaffStore, {
    item: observable,
    isLoading: observable,
    error: observable,
    fetch: action,
    compiledStaffData: computed
})