import React from 'react'
import { makeStyles, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import Row from '../../grid-componoents/row';
import Col from '../../grid-componoents/col';

const steps = [
    require("../../../assets/daftar-online/step-01.png"),
    require("../../../assets/daftar-online/step-02.png"),
];

const useStyles = makeStyles(theme => ({
    root: {
        "& img": {
            width: "100%"
        }
    }
}));
function RegisterOnline({ openModal = false, triggerClose = () => { } }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = useStyles();
    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                maxWidth="lg"
                open={openModal}
                onClose={triggerClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Daftar Online"}</DialogTitle>
                <DialogContent>
                    <DialogContentText className={styles.root}>
                        {steps.map((el, id) => <Row key={id}>
                            <Col size={12}>
                                <img src={el} alt={"step-" + id} />
                            </Col>
                        </Row>)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={triggerClose} color="secondary" variant="contained">
                        Tutup
                    </Button>
                    <Button component="a" href="https://play.google.com/store/apps/details?id=upt.ciumbuleuit.pusekesmas" color="primary" variant="contained" autoFocus>
                        Unduh Teman Online di Google Play
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default RegisterOnline
