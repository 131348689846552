import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const fadeColor = "#E3E3E3";
const useStyles = makeStyles(theme => ({
    root: {
        position: "relative"
    },
    title: ({ smaller }) => ({
        color: fadeColor,
        fontWeight: 700,
        fontSize: (smaller ? "4rem" : "6rem"),

        [theme.breakpoints.down('sm')]: {
            fontSize: (smaller ? "2rem" : "3rem"),
        }
    }),
    content: ({ withQuote, naik, deAbsolute }) => ({
        fontWeight: 100,
        color: theme.palette.text.primary,
        fontSize: "1.2rem",
        position: (deAbsolute ? "relative" : "absolute"),

        marginTop: (naik ? "-6rem" : null),
        marginLeft: 50,

        wordWrap: "break-word",

        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8rem",
            width: "calc(100% - 25px)",
            marginTop: "inherit",
            position: "relative",
            marginLeft: 25,

        },

        "&::before, &::after": {
            position: "absolute",
            color: fadeColor,
            fontWeight: 700,
            fontSize: "8rem",
            content: (withQuote ? "\"''\" " : ' " " '),
            zIndex: -1,
            fontStyle: "italic"
        },
        "&::before": {
            top: -50,
            left: -40
        },
        "&::after": {
            bottom: "-8rem",
            right: -20
        },
    })

}))

function Rodox({ title, children, withQuote, naik, smaller, deAbsolute }) {
    const styles = useStyles({ withQuote: withQuote, naik: naik, smaller: smaller, deAbsolute: deAbsolute });
    return (
        <Typography className={styles.root} component="div">
            <div className={styles.title}>{title}</div>
            <div className={styles.content} component="div">{children}</div>
        </Typography>
    )
}

export default Rodox
