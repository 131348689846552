import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import index from './components/pages/beranda';
import berita from './components/pages/berita';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Navbar from './components/navbar/navbar';
import KegiatanPage from './components/pages/kegiatan/kegiatan';
import PelayananPage from './components/pages/pelayanan/pelayanan';
import { Provider } from 'mobx-react';
import FasilitasStore from './store/fasilitas';
import BeritaStore from './store/berita';
import KegiatanStore from './store/kegiatan';
import PelayananStore from './store/pelayanan';
import StaffStore from './store/staff';
import BannerStore from './store/banner';
import Profil from './components/pages/profil/profil';
import AdminIndex from './components/pages/admin';
import AdminStore from './store/adminStore';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#DFF2E1',
      main: '#8CE8C3'
    },
    secondary: {
      main: '#84DB8C'
    },
    text: {
      primary: "#414141",
      secondary: "#5C5C5C"
    }
  },
  shape: {
    borderRadius: 15
  },
  overrides: {
    "MuiPaper": {
      "rounded": {
        borderRadius: 15
      }
    }
  }
});
function WithNavbar() {
  return <>
    <Navbar shouldTransparent />

    <Route path="/" exact component={index} />
    <Route path="/berita" component={berita} />
    <Route path="/profil" component={Profil} />
    <Route path="/kegiatan" component={KegiatanPage} />
    <Route path="/pelayanan" component={PelayananPage} />
  </>
}
function App() {
  return (
    <BrowserRouter>
      <Provider
        fasilitas={new FasilitasStore()}
        pelayanan={new PelayananStore()}
        berita={new BeritaStore()}
        kegiatan={new KegiatanStore()}
        staff={new StaffStore()}
        banner={new BannerStore()}
        adminStore={new AdminStore()}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/admin*">
              <AdminIndex />
            </Route>
            <Route path="/"><WithNavbar /></Route>
          </Switch>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
