import React from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: "2rem",
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        display: "block",
        "&.active": {
            background: theme.palette.primary.main,
            color: theme.palette.background.default
        },
    },
    
}));

function NavbarMenu(props) {
    const style = useStyles();

    let className = style.root + ((props.active || false) ? " active " : " ") + (props.className || "");
    return <Button {...props} className={className} disableRipple/>;
}

export default NavbarMenu
