import React from 'react'
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: ({bgImage}) => ({
        backgroundColor: "transparent",
        boxShadow: "7px 4px 20px rgba(53, 53, 53, 0.23)",
        position: "relative",
        // zIndex: -3,
        display: "block",
        textDecoration: "none",
        "&:before" :{
            backgroundColor: theme.palette.primary.light,
            content: '" "',
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -3
        },
        "&:after": {
            backgroundColor: theme.palette.primary.light,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${bgImage})`,
            content: '" "',
            width: "calc(100% + 20px)",
            height: "calc(100% + 20px)",
            opacity: 0.2,
            position: "absolute",
            top: -10,
            left: -10,
            zIndex: -3,
            filter: "blur(4px)"
        }
    }),
    konten: {
        zIndex: 5,
        display: "flex",
        flexDirection: "column",
        minHeight: 200,
        color: theme.palette.text.secondary,
        textAlign: "left",
        "& section.info": {
            flexGrow: 1,

            "& h3": {
                color: "black",
                fontWeight: 500
            }
        },
    }
}))

function KegiatanCard({ title, children, jadwal, image, linkTo }) {
    const styles = useStyles({bgImage: image});

    return (
        <Card className={styles.root} component={Link} to={linkTo} raised>
            <CardContent className={styles.konten}>
                <section className="info">
                    <Typography variant="h6" component="h3">{title}</Typography>
                    <Typography variant="body1" component="div">{children}</Typography>
                </section>
                <section>
                    <Typography variant="h6" components="h4">Jadwal</Typography>
                    <Typography variant="body1" component="div">{jadwal}</Typography>
                </section>
            </CardContent>
        </Card>
    )
}

export default KegiatanCard
