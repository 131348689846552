import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const bgImage = require("../../assets/footer/footer-bg.svg");

const heroImage = require("../../assets/footer/sitting.png");

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
        textAlign: "center",
        position: "relative",
        "&::before": {
            backgroundSize: "100%",
            backgroundPosition: "center bottom",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${bgImage})`,
            zIndex: -20,
            content: ' " " ',
            height: "calc( 520/1440 * 100vw)",
            width: "100%",
            position: "absolute",
            bottom: 0,

            [theme.breakpoints.down('sm')]: {
                backgroundPosition: "center -40%",
                height: "calc( 520/1440 * 250vw)",
                backgroundSize: "250vw",
            }
        },
        display: "flex",
        alignItems: "flex-end",

        "& .hero": {
            [theme.breakpoints.down('sm')]: {
                display: "none"
            },
            position: "absolute",
            right: "3rem"
        }
    },
    content: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(2),
        flexBasis: "100%",
    },
    sosmedInfo: {
        display: "flex",
        width: " 100%",
        alignItems: "center",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        flexWrap: "wrap",

        justifyContent: "center",
        "& .sosmed-box": {
            alignItems: "center",
            display: "flex",
            textDecoration: "unset",

            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),

            "& .sosmed-icon": {
                width: 20,
                height: "auto"
            },
            "& .sosmed-info": {
                paddingLeft: theme.spacing(1),
                display: "block",
                color: theme.palette.text.primary,
                overflowWrap: "anywhere",
                [theme.breakpoints.down('sm')]: {
                    width: "100%"
                },
            },
        },
    }
}));

const iconMail = require("../../assets/footer/icon-mail.svg");
const iconPhone = require("../../assets/footer/icon-phone.svg");
function Footer() {
    const style = useStyles();

    return (
        <footer className={style.root}>
            <div className={style.content}>
                <Typography> Hubungi Kami </Typography>
                <div className={style.sosmedInfo}>
                    <Typography className="sosmed-box" component="a" href="tel:(022) 6440-1158">
                        <img src={iconPhone} className="sosmed-icon" alt="phone" />
                        <span className="sosmed-info">(022) 6440-1158</span>
                    </Typography>

                    <Typography className="sosmed-box" component="a" href="mail:uptpkmciumbuleuit@gmail.com">
                        <img src={iconMail} className="sosmed-icon" alt="email" />
                        <span className="sosmed-info">uptpkmciumbuleuit@gmail.com</span>
                    </Typography>
                </div>
            </div>
            <img className="hero" src={heroImage} alt="hero" />
        </footer >
    )
}

export default Footer
