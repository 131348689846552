import React from 'react'
import { Paper, Typography, makeStyles, Box } from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    root: {
        background: "radial-gradient(208.51% 966.15% at 17.95% 81.19%, rgba(140, 232, 195, 0.6) 24.24%, #95E8C6 100%)",

        "& h1": {
            fontWeight: 600,
            marginBottom: theme.spacing(2)
        }
    }
}))

function KartuInfoHalaman({ title, children }) {
    const styles = useStyle();

    return (
        <Paper className={styles.root} elevation={0}>
            <Box padding={4}>
                <Typography variant="h4" component="h1">{title}</Typography>
                <Typography>{children}</Typography>
            </Box>
        </Paper>
    )
}

export default KartuInfoHalaman
