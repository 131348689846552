import React from 'react'
import { makeStyles, Typography, Box, Container, Button } from '@material-ui/core'
import Row from '../../grid-componoents/row';
import Col from '../../grid-componoents/col';

const mainBg = require("../../../assets/home/pendaftaran-offline-bg.svg");
const manequin1 = require("../../../assets/home/hero-1.png");
const manequin2 = require("../../../assets/home/hero-2.png");

const useStyles = makeStyles(theme => ({
    root: {
        // height: 1000,
        position: "relative",
        color: theme.palette.text.primary,

        marginTop: 150,
        marginBottom: 150,

        [theme.breakpoints.down('xs')]: {
            marginTop: 80,
            marginBottom: 80,
        },

        [theme.breakpoints.up('xl')]: {
            marginTop: 280,
            marginBottom: 280,
        },

        display: "flex",

        "&::before": {
            zIndex: -20,
            content: ' " " ',
            // top: -150,
            backgroundImage: `url(${mainBg})`,
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            width: "100%",
            height: "calc(706/1440 * 100vw)",
            position: "absolute",

            [theme.breakpoints.down('xs')]: {
                top: -67,
                height: "calc(706/1440 * 180vw)",
                backgroundSize: "180%"
            },
            [theme.breakpoints.up('sm')]: {
                top: -90,
                height: "calc(706/1440 * 130vw)",
                backgroundSize: "130%"
            },
            [theme.breakpoints.up('md')]: {
                top: -180,
                height: "calc(706/1440 * 130vw)",
                backgroundSize: "130%"
            },
            [theme.breakpoints.up('lg')]: {
                top: -230,
                height: "calc(706/1440 * 100vw)",
                backgroundSize: "100%"
            },
            [theme.breakpoints.up('xl')]: {
                top: -300,
                height: "calc(706/1440 * 100vw)",
                backgroundSize: "100%"
            },
        },

        "& h4": {
            fontWeight: 600,
            [theme.breakpoints.down('xs')]: {
                fontSize: 18,
                fontWeight: 600
            },
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: 24,
                fontWeight: 600
            },
        }
    },
    scheduleContainer: {
        zIndex: 20,
        textAlign: "right",
    },

    manequin1: {
        position: "absolute",
        top: "15rem",
        left: "1.5rem",
        [theme.breakpoints.down('xs')]: {
            top: 0,
            height: "6rem",
            width: "auto",
            left: "0.5rem"
        },
        [theme.breakpoints.up('sm')]: {
            top: 0,
            height: "9rem",
            width: "auto"
        },
        [theme.breakpoints.up('md')]: {
            top: "-5rem",
            height: "13rem",
            width: "auto"
        },
        [theme.breakpoints.up('lg')]: {
            top: "-5rem",
            height: "17rem",
            width: "auto"
        },
    },
    manequin2: {
        position: "absolute",
        top: "15rem",
        left: "18rem",

        [theme.breakpoints.down('xs')]: {
            top: 0,
            height: "6rem",
            width: "auto",
            left: "3rem"
        },
        [theme.breakpoints.up('sm')]: {
            top: 0,
            height: "9rem",
            width: "auto",
            left: "7rem"
        },
        [theme.breakpoints.up('md')]: {
            top: "-5rem",
            height: "13rem",
            width: "auto",
            left: "13rem"
        },
        [theme.breakpoints.up('lg')]: {
            top: "-5rem",
            height: "17rem",
            width: "auto"
        },
    },
    btnRegister: {
        background: "linear-gradient(90deg, #11998E 0%, #38EF7D 100%)",
        color: "white",
        fontWeight: "bold",
        borderRadius: "2rem",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}))

function RegistrasiOffline({ onRegisterOnlineClick = () => { } }) {
    const style = useStyles();

    return (
        <div className={style.root}>
            <Container style={{ justifyItems: "center" }}>
                <Box className={style.scheduleContainer}>
                    <Row className="end-xs">
                        <Col size={{ xs: 8, md: 12 }}>
                            <Typography variant="h4">Jam Pendaftaran Offline</Typography>
                            <Typography variant="subtitle2" component="p">07.30 - 11.00</Typography>
                            <Typography variant="body1" component="div"><Button className={style.btnRegister} onClick={onRegisterOnlineClick}>Pendafataran Online</Button></Typography>
                        </Col>
                    </Row>
                </Box>
            </Container>
            <img className={style.manequin1} src={manequin1} alt="" />
            <img className={style.manequin2} src={manequin2} alt="" />
        </div>
    )
}

export default RegistrasiOffline
