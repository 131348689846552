import React from 'react'

function Col(props) {
    const { size, defaultViewPort = "xs" } = props;
    let className = (props.className|| "") + " ";
    if (isNaN(size)) {
        for (const prop in size) {
            className += ` col-${prop}-${size[prop]}`;
        }
    } else {
        className = `col-${defaultViewPort}-${size}`;
    }

    return (
        <div  {...props} className={className} />
    )
}

export default Col