import React, { useEffect } from 'react'
import { makeStyles, Box, Container, Typography } from '@material-ui/core'
import Rodox from './rodox';
import Col from '../../grid-componoents/col';
import Row from '../../grid-componoents/row';
import Staffs from './staffs';
import { useStores } from '../../../store/use-store';
import { observer } from 'mobx-react';
import Buled from '../../buled/buled';
const fadeColor = "#CACACA";

let imgBgHome = require("../../../assets/profil/bg-upper.svg")
let imgBgFooter = require("../../../assets/profil/bg-bottom.svg")
const useStyle = makeStyles(theme => ({
    root: {
        "& img.hero": {
            maxWidth: "100%",
            height: "auto"
        },
        "& img.hero-1": {
            maxWidth: "60%",
            height: "auto",
            marginRight: "-60px"
        },
        [theme.breakpoints.down('md')]: {
            "& .hilang-xs": {
                visibility: "hidden"
            }
        }
    },
    "@global": {
        [theme.breakpoints.up('md')]: {
            "body": {
                backgroundImage: `url(${imgBgHome})`,
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center -5rem"
            }
        }
    },
    listingMisi: {
        listStyleType: "disc",
        "& li": {
            marginBottom: theme.spacing(2),
        }
    },
    judul: {
        color: fadeColor,
        fontWeight: 600,
        fontSize: "4rem",
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            fontSize: "2rem",
        }
    },
    footer: {
        background: "linear-gradient(75.1deg, #EDFCF6 4.05%, rgba(237, 252, 246, 0.33) 97.42%)",
        marginTop: "30vh",
        paddingBottom: theme.spacing(10),
        position: "relative",

        [theme.breakpoints.down('sm')]: {
            marginTop: "10vh",
        },
        "&::before": {
            content: ' " " ',
            backgroundImage: `url(${imgBgFooter})`,
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            height: "calc(100% - 30vh)",
            width: "100%",
            position: "absolute",
            zIndex: -1,
            top: "-30vh",

            [theme.breakpoints.down('sm')]: {
                top: "-10vh"
            },
        },
        "& .konten": {
            padding: 0,
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(5)
            }
        }
    },
    sosmed: {
        display: "flex",
        width: " 100%",
        alignItems: "center",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),

        "& .sosmed-icon": {
            width: 30,
            height: "auto"
        },
        "& .sosmed-info": {
            display: "block",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            textDecoration: "unset",
            color: theme.palette.text.primary,
            flexGrow: 1,
            overflowWrap: "anywhere"
        },
    },
}))

let imgHeroBanner = require("../../../assets/profil/hero-1.png");
let imgHeroMisi = require("../../../assets/profil/hero-4.png");
let imgHeroBudaya = require("../../../assets/profil/hero-2.png");
let imgHeroFooter = require("../../../assets/profil/hero-3.png");
let imgStruktur = require("../../../assets/profil/struktur.png");

let iconSosmed = {
    whatsapp: require("../../../assets/icons/icon-whatsapp.svg"),
    phone: require("../../../assets/icons/icon-phone.svg"),
    email: require("../../../assets/icons/icon-email.svg"),
    instagram: require("../../../assets/icons/icon-instagram.svg")
}
function Profil() {
    const styles = useStyle();

    const { staff } = useStores();

    document.title = "Profil | Puskesmas Ciumbuleuit";

    useEffect(() => {
        if (!staff.hasFullRefresh) {
            staff.fetch();
        }
        return () => { };
    }, [staff])

    return (
        <div className={styles.root}>
            <Buled xs={{ x: "0.3rem", y: "3.5rem", size: "4rem" }} md={{ x: "6rem", y: "unset", size: "10rem" }} style={{ opacity: 0.7 }} rotate="147.33deg" />
            <div className="container-fluid">
                <Row>
                    <Col size={{ xs: 12, md: 4, "md-offset": 2 }} >
                        <Box marginTop={10}>
                            <Rodox title="VISI" withQuote>
                                <div style={{ textTransform: "uppercase" }}>TERWUJUDNYA KECAMATAN CIDADAP YANG UNGGUL, NYAMAN, SEJAHTERA DAN AGAMIS TAHUN 2020</div>
                            </Rodox>
                        </Box>
                    </Col>
                </Row>
            </div>
            <div style={{ textAlign: "right", overflow: "hidden" }}>
                <img src={imgHeroBanner} alt="hero-1" style={{ width: 400 }} className="hero hero-1" />
            </div>
            <Buled xs={{ x: "-4rem", y: "0rem", size: "0rem" }} md={{ x: "20%", y: "unset", size: "4rem" }} style={{ opacity: 0.7 }} rotate="85deg" />
            <Buled xs={{ x: "70%", y: "14rem", size: "2rem" }} md={{ x: "80%", y: "8rem", size: "8rem" }} style={{ opacity: 0.9 }} rotate="85deg" />
            <Buled xs={{ x: "80%", y: "30rem", size: "4rem" }} md={{ x: "65%", y: "30rem", size: "5rem" }} style={{ opacity: 0.9 }} rotate="85deg" />

            <div className="container-fluid">
                <Row>
                    <Col size={{ xs: 9, md: 4, "xs-offset": 3, "md-offset": 5 }} >
                        <Box marginTop={10}>
                            <Rodox title="MISI" naik>
                                <ul className={styles.listingMisi}>
                                    <li>Membangun Masyarakat yang humanis, agamis, berkualitas dan berdaya saing</li>
                                    <li>Mewujudkan Tata Kelola Pemerintahan yang Efektif, Efisien, Bersih dan Melayani</li>
                                    <li>Membangun Perekonomian yang Mandiri, Kokoh, dan Berkeadilan</li>
                                    <li>Mewujudkan Bandung nyaman melalui perencanaan tata ruang, pembangunan infrastruktur serta pengendalian pemanfaatan ruang yang berkualitas dan berwawasan lingkungan</li>
                                    <li>Mengembangkan pembiayaan kota yang partisipatif, kolaboratif dan terintegrasi</li>
                                </ul>
                            </Rodox>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col size={{ xs: 8, md: 6, lg: 4 }}>
                        <img src={imgHeroMisi} alt="hero" className="hero" />
                    </Col>
                </Row>
            </div>

            <div className="container-fluid">
                <Row className="middle-xs">
                    <Col size={{ xs: 10, md: 5, "xs-offset": 1, "md-offset": 1 }} >
                        <Row>
                            <Col size={{ xs: 10, "xs-offset": 1 }}>
                                <Box marginTop={12}>
                                    <Buled xs={{ x: "-4rem", y: "3rem", size: "0rem" }} md={{ x: "-3rem", y: "-4rem", size: "5rem" }} style={{ opacity: 0.9 }} rotate="85deg" />

                                    <Rodox title="Budaya Kerja" smaller deAbsolute>
                                        Cerdik,Etika, Ramah, Ikhlas, Akuntabel
                                </Rodox>
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col size={{ xs: 10, "xs-offset": 2 }}>
                                <Box marginTop={12}>
                                    <Buled xs={{ x: "-10rem", y: "unset", size: "10rem" }} md={{ x: "30rem", y: "-3rem", size: "2rem" }} style={{ opacity: 0.9 }} rotate="85deg" />
                                    <Rodox title="Tata Nilai" smaller deAbsolute>
                                        Profesional, Kolaborasi, Inovasi
                                </Rodox>
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col size={{ xs: 10, "xs-offset": 0 }}>
                                <Box marginTop={12}>
                                    <Buled xs={{ x: "4rem", y: "3rem", size: "0rem" }} md={{ x: "-4rem", y: "-3rem", size: "4rem" }} style={{ opacity: 0.9 }} rotate="150deg" />

                                    <Rodox title="Motto" smaller deAbsolute>
                                        Ciumbuleuit SATUNADA (Ciumbuleuit Sahabat Kesehatan Untuk Anda)
                                </Rodox>
                                </Box>
                            </Col>
                        </Row>
                    </Col>
                    <Col size={{ xs: 8, "xs-offset": 4, md: 5, "md-offset": 1 }}>
                        <div style={{ textAlign: "right" }}>
                            <Buled xs={{ x: "6rem", y: "3rem", size: "4rem" }} md={{ x: "-4rem", y: "-3rem", size: "0rem" }} style={{ opacity: 0.6 }} rotate="150deg" />
                            <img src={imgHeroBudaya} alt="hero" className="hero" />
                        </div>
                    </Col>
                </Row>
            </div>

            <Buled xs={{ x: "4rem", y: "3rem", size: "4rem" }} md={{ x: "10%", y: "8rem", size: "18rem" }} style={{ opacity: 0.7 }} rotate="85deg" />
            <Buled xs={{ x: "65%", y: "30rem", size: "5rem" }} md={{ x: "60%", y: "28rem", size: "10rem" }} style={{ opacity: 0.7 }} rotate="85deg" />
            <Buled xs={{ x: "-2rem", y: "60rem", size: "15rem" }} md={{ x: "80%", y: "60rem", size: "4rem" }} style={{ opacity: 0.9 }} rotate="85deg" />
            <Box marginY={10}>
                <Container>
                    <div className="container-fluid">
                        <Row>
                            <Col size={{ xs: 12, md: 6, "xs-offset": 0, "md-offset": 3 }}>
                                <Buled xs={{ x: "4rem", y: "3rem", size: "4rem" }} md={{ x: "calc(50% + 4rem)", y: "-10rem", size: "4rem" }} style={{ opacity: 0.9 }} rotate="150deg" />

                                <Typography className={styles.judul}>Staff</Typography>

                                <Staffs staffData={staff.item} staffCompiledData={staff.compiledStaffData} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Box>

            <Box marginY={10}>
                <div className="container-fluid">
                    <Row>
                        <Col size={12}>
                            <Buled xs={{ x: "80%", y: "-2rem", size: "2rem" }} md={{ x: "0%", y: "0rem", size: "0rem" }} style={{ opacity: 0.9 }} rotate="85deg" />

                            <Typography className={styles.judul}>Struktur Organisasi</Typography>
                            <img src={imgStruktur} alt="struktur" style={{ width: "100%" }} />
                        </Col>
                    </Row>
                </div>
            </Box>

            <footer className={styles.footer}>
                <Box className="konten">
                    <div className="container-fluid">
                        <Row>
                            <Col size={12}>
                                <Typography className={styles.judul} style={{ textAlign: "left" }}>Kontak Kami</Typography>
                            </Col>
                        </Row>
                        <Row className="middle-xs">
                            <Col size={{ xs: 12, lg: 6 }}>
                                <Box marginX={4}>
                                    <div className={styles.sosmed}>
                                        <img className="sosmed-icon" alt="icon" src={iconSosmed.whatsapp} />
                                        <Typography className="sosmed-info" component="a" href="https://wa.me/6289656348813">0896-5634-8813</Typography>
                                    </div>
                                    <div className={styles.sosmed}>
                                        <img className="sosmed-icon" alt="icon" src={iconSosmed.phone} />
                                        <Typography className="sosmed-info" component="a" href="tel:(022) 6440-1158">(022) 6440-1158</Typography>
                                    </div>
                                    <div className={styles.sosmed}>
                                        <img className="sosmed-icon" alt="icon" src={iconSosmed.email} />
                                        <Typography className="sosmed-info" component="a" href="mail:uptpkmciumbuleuit@gmail.com">uptpkmciumbuleuit@gmail.com</Typography>
                                    </div>
                                    <div className={styles.sosmed}>
                                        <img className="sosmed-icon" alt="icon" src={iconSosmed.instagram} />
                                        <Typography className="sosmed-info" component="a" href="https://instagram.com/igpkmciumbuleuit">@igpkmciumbuleuit</Typography>
                                    </div>
                                </Box>
                            </Col>
                            <Col size={{ xs: 12, lg: 6 }} className="first-md">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.56903934933865!2d107.60297457138624!3d-6.8780666925354685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e63082c5e0fb%3A0x216df948453880e0!2sPuskesmas%20Ciumbuleuit!5e0!3m2!1sen!2sid!4v1582529045093!5m2!1sen!2sid"
                                    width="100%"
                                    height={250}
                                    frameBorder={0}
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    title="googlemap"
                                />
                            </Col>
                        </Row>
                        <div style={{position:"relative", top: "-3rem"}}>
                            <Row className="end-xs">
                                <Col size={{ xs: 2 }} style={{ alignSelf: "flex-end" }} className="hilang-xs">
                                    <img src={imgHeroFooter} alt="hero-bawah" className="hero" />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Box>
            </footer>
        </div>
    )
}

export default observer(Profil);
