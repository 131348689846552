import { decorate, observable, action } from "mobx";
import { computedFn } from "mobx-utils";
import { axios } from "./_api";

// import { axios } from "./_api";
export default class AdminStore {
    barTitle = ""
    userProfile = {
        _id: 0,
        username: undefined
    }

    items = {};

    imageVerVector = Date.now();

    createItem(endpoint, data) {
        return axios.post(`manage/${endpoint}`, data).then(resp => {
            if (!resp.data.status) {
                throw new Error("Unexpected error: " + resp.data.error.description)
            }

            if (!this.items.hasOwnProperty(endpoint)) {
                this.items[endpoint] = {};
            }
            this.items[endpoint][resp.data.data._id] = resp.data.data;
            return Promise.resolve(resp.data.data);
        });
    }

    deleteItem(endpoint, data) {
        return axios.delete(`manage/${endpoint}/${data._id}`, data).then(resp => {
            if (!resp.data.status) {
                throw new Error("Unexpected error: " + resp.data.error.description)
            }

            if (!this.items.hasOwnProperty(endpoint)) {
                this.items[endpoint] = {};
            }
            delete this.items[endpoint][resp.data.data._id];
            return Promise.resolve(resp.data.data);
        });
    }

    updateItem(endpoint, data) {
        return axios.put(`manage/${endpoint}/${data._id}`, { ...data, "_id": undefined }).then(resp => {
            if (!resp.data.status) {
                throw new Error("Unexpected error: " + resp.data.error.description)
            }

            if (!this.items.hasOwnProperty(endpoint)) {
                this.items[endpoint] = {};
            }
            this.items[endpoint][resp.data.data._id] = resp.data.data;
            return Promise.resolve(resp.data.data);
        });
    }

    fetchItem(endpoint, id) {
        return axios.get(`manage/${endpoint}/${id}`).then(resp => {
            if (!resp.data.status) {
                throw new Error("Unexpected error: " + resp.data.error.description)
            }

            if (!this.items.hasOwnProperty(endpoint)) {
                this.items[endpoint] = {};
            }
            this.items[endpoint][resp.data.data._id] = resp.data.data;
            return Promise.resolve(resp.data.data);
        });
    }

    fetchItems(endpoint) {
        return axios.get(`manage/${endpoint}`).then(resp => {
            if (!resp.data.status) {
                throw new Error("Unexpected error: " + resp.data.error.description)
            }

            // proccess data: 
            let temp = {};
            resp.data.data.map((el) => {
                return temp[el._id] = el;
            })
            this.items[endpoint] = temp;
            return Promise.resolve(temp);
        });
    }

    getItems(endpoint) {
        let items = { ...this.items };
        if (items && !items.hasOwnProperty(endpoint)) {
            return [];
        }
        return [...Object.values(items[endpoint])];
    }

    getItem(endpoint, id) {
        let items = { ...this.items };

        if (!items.hasOwnProperty(endpoint)) {
            return {};
        }

        if (!items[endpoint].hasOwnProperty(id)) {
            return {};
        }

        return { ...items[endpoint][id] };
    }

    createImage(endpoint, id, data) {
        return axios.post(`manage/${endpoint}/${id}/image/`, data).then(resp => {
            if (!resp.data.status) {
                throw new Error("Unexpected error: " + resp.data.error.description)
            }

            if (!this.items.hasOwnProperty(endpoint)) {
                this.items[endpoint] = { [resp.data.data._id]: {} };
            }

            if (!this.items[endpoint]?.[resp.data.data._id]) {
                this.items[endpoint][resp.data.data._id] = {};
            }

            if (!this.items[endpoint]?.[resp.data.data._id]?.['images']) {
                this.items[endpoint][resp.data.data._id].images = [];
            }
            this.items[endpoint].[resp.data.data._id].images.push(resp.data.data.path);
            this.imageVerVector = Date.now();
            return Promise.resolve(resp.data.data);
        });
    }

    updateImage(endpoint, id, index, data) {
        return axios.post(`manage/${endpoint}/${id}/image/${index}`, data).then(resp => {
            if (!resp.data.status) {
                throw new Error("Unexpected error: " + resp.data.error.description)
            }

            if (!this.items.hasOwnProperty(endpoint)) {
                this.items[endpoint] = {};
            }
            this.imageVerVector = Date.now();
            return Promise.resolve(resp.data.data);
        });
    }

    deleteImage(endpoint, id, index) {
        return axios.delete(`manage/${endpoint}/${id}/image/${index}`).then(resp => {
            if (!resp.data.status) {
                throw new Error("Unexpected error: " + resp.data.error.description)
            }

            if (!this.items.hasOwnProperty(endpoint)) {
                this.items[endpoint] = {};
            }
            delete this.items[endpoint]?.[resp.data.data._id]?.['images'][index];
            this.imageVerVector = Date.now();
            return Promise.resolve(resp.data.data);
        })

    }
}

decorate(AdminStore, {
    items: observable,
    barTitle: observable,
    imageVerVector: observable,
    getItems: computedFn,
    getItem: computedFn,
    fetchItems: action,
    fetchItem: action,

    updateItem: action,
    createItem: action,
    deleteItem: action,


    updateImage: action,
    createImage: action,
    deleteImage: action
})