import { decorate, observable, action } from "mobx";

import { axios } from "./_api";
export default class FasilitasStore {
    error = {};

    item = [];
    isLoading = true;

    fetchFasilitas() {
        this.isLoading = true;

        axios.get("manage/infocard", { params: { type: "fasilitas" } }).then(data => {
            this.isLoading = false;
            if (data.data.status) {
                this.item = data.data.data;
            } else {
                // error happened.
                this.error = {
                    title: data.data.error.title,
                    description: data.data.error.description
                }
            }
        }).catch(err=>{
            console.log(err);
            this.isLoading = false;
            this.error = {
                title: "Terjadi masalah pada saat mengontak server. (HTTP)",
                description: "Mohon cek kembali koneksi anda. Jika masalah terus berlanjut, mohon hubungi developer."
            }
        });
    }
}

decorate(FasilitasStore, {
    item: observable,
    isLoading: observable,
    error: observable,
    fetchFasilitas: action
})