import React from 'react'
import { Card, CardContent, makeStyles, Typography, Button, Box } from '@material-ui/core'
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.light,
        boxShadow: "7px 4px 20px rgba(53, 53, 53, 0.23)",
        position: "relative",
        textAlign: "center"
    },
    konten: {
        zIndex: 5,
        display: "flex",
        flexDirection: "column",
        minHeight: 200,
        color: theme.palette.text.secondary,
        "& section.info": {
            flexGrow: 1,
            "& h3": {
                color: "black",
                fontWeight: 500
            },
            "& img": {
                height: "3rem",
                width: "auto",
                marginBottom: theme.spacing(2)
            },
            "&>div": {
                overflow: "hidden",
                position: "relative",
                maxHeight: 150,
                "&:after": {
                    content: '" "',
                    height: 25,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    position: "absolute",
                    background: `linear-gradient(0deg, ${theme.palette.primary.light} 10%, transparent)`
                }
            }
        },
    }
}));


function FasilitasCard({ title, children, jadwal, image, linkTo }) {

    const styles = useStyles();
    return (
        <Card className={styles.root} raised>
            <CardContent className={styles.konten}>
                <section className="info">
                    <Typography variant="h6" component="h3">{title}</Typography>
                    <img src={image} alt="hero" />
                    <Typography variant="body1" component="div">{children}</Typography>
                </section>
                <section>
                    <Typography variant="h6" components="h4">Jadwal</Typography>
                    <Typography variant="body1" component="div">{jadwal}</Typography>
                    <Box marginTop={2}>
                        <Button variant="contained" component={Link} to={linkTo}>Pelajari lebih lanjut</Button>
                    </Box>
                </section>
            </CardContent>
        </Card>
    )
}

export default FasilitasCard
