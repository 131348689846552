import React, { useEffect } from 'react'
import { Container, makeStyles, CircularProgress } from '@material-ui/core'
import BeritaCard from './berita-card'
import Row from '../../grid-componoents/row'
import Col from '../../grid-componoents/col'
import Footer from '../../footer/footer'
import { useStores } from '../../../store/use-store';
import { observer } from 'mobx-react'
import { If, Else, Then } from 'react-if'

const useStyles = makeStyles(theme => ({
    newsContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    parent: {
        minHeight: "80vh"
    }
}))

function BeritaLister() {
    const styles = useStyles();
    const { berita } = useStores();

    useEffect(() => {
        document.title = "Berita | Puskesmas Ciumbuleuit";

        if (!berita.hasFullRefresh) {
            berita.fetch();
        }
        return () => { };
    }, [berita])
    return (
        <>
            <Container className={styles.parent}>
                <div className="container-fluid">
                    <If condition={berita.isLoading}>
                        <Then>
                            <Row className="center-xs">
                                <Col size={12}>
                                    <CircularProgress />
                                </Col>
                            </Row>
                        </Then>
                        <Else>
                            {berita.items.map((data, i) =>
                                <Row key={i}>
                                    <Col size={12}>
                                        <div className={styles.newsContainer}>
                                            <BeritaCard title={data.title} image={(data.images || [null])[0]} linkTo={"/berita/" + data._id} date={data.created_on}>
                                                {data.description}
                                            </BeritaCard>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Else>
                    </If>
                </div>
            </Container>
            <Footer />
        </>
    )
}

export default observer(BeritaLister);
