import { Box, Button, Divider, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, makeStyles, MenuItem, Typography, Menu, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import { MoreVert } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { Else, If, Then, When } from 'react-if';
import { useAdminStore } from '../../../../../../store/use-store';


const useStyles = makeStyles(theme => ({
    imgList: {
        flexWrap: "no-wrap",
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background: 'rgba(0,0,0, 0.7)',
    },
    imgItem: {
        cursor: "pointer"
    }
}))

function getFilename(fullpath) {
    fullpath = fullpath || "";
    let parts = fullpath.split("/");
    let fnOnly = parts.pop().split(".");
    return fnOnly?.[0];
}

var TheActionButton = observer(({ handleTriggerDelete = () => { }, handleTriggerUpdate = () => { }, handleTriggerOpen = () => { }, index = 0 }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const styles = useStyles();


    function handleClose() {
        setAnchorEl(null)
    }
    return <>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVert className={styles.title} />
        </IconButton>
        <Menu
            id={"image-option-menu-" + index}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={() => {
                handleClose();
                handleTriggerOpen();
            }}>Lihat di Tab Baru</MenuItem>
            <MenuItem onClick={() => {
                handleClose();
                handleTriggerUpdate();
            }}>Ganti</MenuItem>
            <Divider />
            <MenuItem onClick={() => {
                handleClose();
                handleTriggerDelete();
            }}>Hapus</MenuItem>
        </Menu>
    </>
})

let ImageBoxIndex = observer(({ rule, globalRule, itemId, onError = () => { } }) => {
    // eslint-disable-next-line
    const adminStore = useAdminStore();
    const imageUploadRef = useRef(null);
    const imageUpdatedRef = useRef(null);
    const styles = useStyles();
    const images = adminStore.getItem(rule.endpoint, itemId)?.images || [];

    const [imageViewer, setImageViewer] = useState(undefined)
    const [triggerDeleteIndex, setTriggerDeleteIndex] = useState(undefined)
    const [triggerUpdateIndex, setTriggerUpdateIndex] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(undefined)

    function handleUploadTrigger(e) {
        imageUploadRef.current.click();
    }

    async function handleFileUpload(file, target) {
        setIsLoading(true);

        //prep file upload handler
        let formData = new FormData();
        formData.append("image", file[0]);
        try {
            if (target != null) {
                await adminStore.updateImage(rule.endpoint, itemId, target, formData);
            } else {
                await adminStore.createImage(rule.endpoint, itemId, formData);
            }
            // force refresh whole item
            await adminStore.fetchItem(rule.endpoint, itemId);

        } catch (resp) {
            if (resp.response && resp.response.data && resp.response.data.error) {
                setHasError(<>
                    <DialogTitle>{resp.response.data.error.title} [{resp.response.data.error.error_code}]</DialogTitle>
                    <DialogContent><Typography>{resp.response.data.error.description}</Typography></DialogContent>
                </>)
            } else {
                console.error(resp);
                console.log(JSON.stringify(resp));
                setHasError(<>
                    <DialogTitle>Terjadi Error</DialogTitle>
                    <DialogContent>
                        <Typography>{resp.message}</Typography>
                    </DialogContent>
                </>)
            }
            return onError(resp);
        } finally {
            setIsLoading(false);
            // reset input:
            imageUploadRef.current.value = "";
        }
    }

    function handleOpen(image, newTab) {
        if (newTab) {
            window.open(image, "_blank").focus();
            return;
        }
        setImageViewer(image);
    }

    function handleDelete(idx) {
        setIsLoading(true);

        adminStore.deleteImage(rule.endpoint, itemId, idx).then(() => {
            return adminStore.fetchItem(rule.endpoint, itemId);
        }).catch((resp) => {
            if (resp.response && resp.response.data && resp.response.data.error) {
                setHasError(<>
                    <DialogTitle>{resp.response.data.error.title} [{resp.response.data.error.error_code}]</DialogTitle>
                    <DialogContent><Typography>{resp.response.data.error.description}</Typography></DialogContent>
                </>)
            } else {
                console.error(resp);
                console.log(JSON.stringify(resp));
                setHasError(<>
                    <DialogTitle>Terjadi Error</DialogTitle>
                    <DialogContent>
                        <Typography>{resp.message}</Typography>
                    </DialogContent>
                </>)
            }
            return onError(resp);
        }).finally(() => {
            setIsLoading(false);
            setTriggerDeleteIndex(undefined);
            // reset input:
            imageUploadRef.current.value = "";
            imageUpdatedRef.current.value = "";
        })
    }

    function handleImageUpdate(index) {
        setTriggerUpdateIndex(index);
        imageUpdatedRef.current.click();
    }

    return (
        <>
            <When condition={isLoading}>
                <LinearProgress />
            </When>
            <Box padding={3}>
                <Grid container alignItems="flex-start">
                    <Grid item xs={6}>
                        <Typography variant="h5">Gambar</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box textAlign="right">
                            <Button onClick={handleUploadTrigger} variant="contained" disabled={itemId === "new"}>Unggah Baru</Button>
                            <input type="file" style={{ display: 'none' }} ref={imageUploadRef} onChange={(e) => handleFileUpload(e.target.files, null)} />
                            <input type="file" style={{ display: 'none' }} ref={imageUpdatedRef} onChange={(e) => handleFileUpload(e.target.files, triggerUpdateIndex)} />
                        </Box>
                    </Grid>
                </Grid>
                <If condition={itemId === "new"}>
                    <Then>
                        <Box marginTop={3} padding={3} style={{ backgroundColor: blueGrey[200] }}>
                            <Typography>Gambar hanya dapat diunggah pada saat entri ini selesai disimpan. Mohon lengkapi dulu formulir ini lalu tekan tombol simpan.</Typography>
                        </Box>
                    </Then>
                    <Else>
                        <Box marginTop={3}>
                            <ImageList className={styles.imgList} cols={4}>
                                {images?.map((img, idx) => <ImageListItem
                                    key={rule.endpoint + "-image-" + idx}
                                    className={styles.imgItem}>
                                    <img src={img + "?v=" + adminStore.imageVerVector} alt={"Gambar ke-" + idx} onClick={() => handleOpen(img, false)} />
                                    <ImageListItemBar title={getFilename(img)} classes={{
                                        root: styles.titleBar,
                                        title: styles.title,
                                    }}
                                        actionIcon={<TheActionButton
                                            index={idx}
                                            handleTriggerOpen={() => handleOpen(img, true)}
                                            handleTriggerDelete={() => setTriggerDeleteIndex(idx)}
                                            handleTriggerUpdate={() => handleImageUpdate(idx)}
                                        />}
                                    />
                                </ImageListItem>)}
                            </ImageList>
                        </Box>
                    </Else>
                </If>
            </Box>

            <Dialog open={(triggerDeleteIndex !== undefined)} onClose={() => setTriggerDeleteIndex(undefined)}>
                {((hasImage) => hasImage ? <><DialogTitle>Hapus {getFilename(images[triggerDeleteIndex])}?</DialogTitle>
                    <DialogContent>
                        <div>
                            <img src={images[triggerDeleteIndex] + "?v=" + adminStore.imageVerVector} alt={getFilename(images[triggerDeleteIndex])} style={{ width: "100%", height: "100%", maxHeight: "60vh", margin: "auto" }} />
                        </div>
                        <Typography>Apakah Anda yakin untuk menghapus gambar ini?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={() => setTriggerDeleteIndex(undefined)} autofocus>Tidak</Button>
                        <Button onClick={() => handleDelete(triggerDeleteIndex)}>Ya</Button>
                    </DialogActions></> : null)(images && triggerDeleteIndex !== undefined && images[triggerDeleteIndex])}
            </Dialog>

            <Dialog open={!!imageViewer} onClose={() => setImageViewer(undefined)}>
                <DialogTitle>{getFilename(imageViewer)}</DialogTitle>
                <DialogContent>
                    <img src={imageViewer + "?v=" + adminStore.imageVerVector} alt={getFilename(imageViewer)} style={{ width: "100%", height: "100%", maxHeight: "60vh", margin: "auto" }} />
                </DialogContent>
            </Dialog>

            <Dialog open={!!hasError}>
                {hasError}
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={() => setHasError(undefined)}>Oke</Button>
                </DialogActions>
            </Dialog>
        </>
    )
});

export default (ImageBoxIndex)
