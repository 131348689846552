import React from 'react'

function Row(props) {
    let className = "row " + (props.className || "");
    return (
        <div {...props} className={className}/>
    )
}

export default Row
