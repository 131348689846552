import React from 'react'
import { Route } from 'react-router-dom'
import BeritaLister from "./lister";
import BeritaReader from "./reader/index";

function BeritaIndex({match}) {
    return (
        <>
            <Route path={match.path} exact component={BeritaLister}/>
            <Route path={match.path + "/:id"} component={BeritaReader}/>
        </>
    )
}

export default BeritaIndex
