import { Box, Button, Chip, FormHelperText, Grid, Input, InputLabel, LinearProgress, makeStyles, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { AddCircle, Code, ErrorOutline, List } from '@material-ui/icons';
import { Alert, AlertTitle, Skeleton } from "@material-ui/lab";
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Else, If, Then, When } from 'react-if';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { useAdminStore } from '../../../../store/use-store';
import ImageBoxIndex from './components/imagebox';
import { buildDefaultInput, exportInputForRequest, representItemToText } from './utils/translate';

const useStyle = makeStyles((theme) => ({
    fieldItem: {
        marginBottom: theme.spacing(1)
    },
    "@global": {
        "body": {
            background: theme.palette.grey[200]
        }
    }
}))

function AdminManageEditor({ rule, globalRule }) {
    const match = useRouteMatch("/admin/manage/:endpoint/:id");
    const styles = useStyle();

    let adminStore = useAdminStore();
    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(undefined)

    const [formItem, setformItem] = useState({})
    let fields = rule.fields;

    useEffect(() => {
        let fields = rule.fields;
        // load items
        Promise.all(Object.keys(fields).map(element => {
            let frule = fields[element];
            if (frule.link) {
                return adminStore.fetchItems(frule.link);
            }

            return Promise.resolve()
        })).then(async () => {
            if (match.params.id === "new") {
                setformItem({ ...buildDefaultInput(rule) });
                return Promise.resolve();
            }
            let data = await adminStore.fetchItem(rule.endpoint, match.params.id)
            setformItem({ ...buildDefaultInput(rule), ...data });
            return Promise.resolve();
        }).then(() => {
            setIsLoading(false);
        }).catch((resp) => {
            if (resp.response && resp.response.data && resp.response.data.error) {
                setHasError(<>
                    <AlertTitle>{resp.response.data.error.title} [{resp.response.data.error.error_code}]</AlertTitle>
                    <div><p>{resp.response.data.error.description}</p></div>
                </>)
            } else {
                console.error(resp);
                console.log(JSON.stringify(resp));
                setHasError(<>
                    <AlertTitle>Error happened</AlertTitle>
                    <div>
                        <p>{resp.message}</p>
                        <p><small>Because it's a browser(/network) related error, the error has been emitted to the console.</small></p>
                    </div>
                </>)
            }

        });
        return () => { }
    }, [adminStore, rule, match.params.id]);

    const handleEditorUpdate = (eventFormItem, el) => (value) => {
        setformItem(data => ({ ...data, [el]: value }));
    }

    let history = useHistory();
    async function handleSave() {
        setIsLoading(true);
        try {
            if (match.params.id === "new") {
                let data = await adminStore.createItem(rule.endpoint, exportInputForRequest(rule, formItem))
                history.push("/admin/manage/" + rule.endpoint + "/" + data._id);
            } else {
                let exportedData = exportInputForRequest(rule, formItem);
                exportedData["_id"] = formItem["_id"];
                await adminStore.updateItem(rule.endpoint, exportedData)
            }
        } catch (resp) {
            if (resp.response && resp.response.data && resp.response.data.error) {
                setHasError(<>
                    <AlertTitle>{resp.response.data.error.title} [{resp.response.data.error.error_code}]</AlertTitle>
                    <div><p>{resp.response.data.error.description}</p></div>
                </>)
            } else {
                console.error(resp);
                console.log(JSON.stringify(resp));
                setHasError(<>
                    <AlertTitle>Error happened</AlertTitle>
                    <div>
                        <p>{resp.message}</p>
                    </div>
                </>)
            }
        }
        setIsLoading(false);
    }

    return (
        <div>
            <Grid container alignItems="center">
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">{match.params.id !== "new" ? "Ubah" : "Buat"}</Typography>
                    <Typography variant="h5">{rule.title}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box textAlign="right">
                        <If condition={match.params.id === "new"}>
                            <Then>
                                <Button variant="contained" color="primary" startIcon={<List />} component={Link} to={"/admin/manage/" + rule.endpoint}> Lihat Daftar</Button>
                            </Then>
                            <Else>
                                <Button variant="contained" color="primary" startIcon={<AddCircle />} component="a" href={"/admin/manage/" + rule.endpoint + "/new"}> Buat Baru</Button>
                            </Else>
                        </If>
                    </Box>
                </Grid>
            </Grid>

            <When condition={!!hasError} variant="filled">
                <Box marginY={3}>
                    <Alert onClose={() => setHasError(undefined)} severity="error" variant="filled">
                        {hasError}
                    </Alert>
                </Box>
            </When>

            <Box marginY={3}>
                <Paper>
                    <When condition={isLoading}>
                        <LinearProgress />
                    </When>
                    <Box padding={3}>
                        <Grid container spacing={3}>
                            {Object.keys(fields).map(el => {
                                let frule = fields[el];
                                let inputid = "input-" + el;

                                let editor = undefined;
                                if (isLoading) {
                                    editor = <Skeleton variant="text" />
                                } else if (frule.type === "select") {
                                    if (frule.choices) {
                                        if (Array.isArray(frule.choices)) {
                                            editor = <Select required={frule.required} value={formItem[el]} id={inputid} name={inputid} fullWidth disabled={!frule.editable} onChange={(event) => {
                                                setformItem({ ...formItem, [el]: event.target.value });
                                            }}>
                                                <MenuItem value={undefined} disabled>--Pilih--</MenuItem>
                                                {frule.choices.map((it) => <MenuItem value={it} key={el + "-" + it}>{it}</MenuItem>)}
                                            </Select>
                                        } else {
                                            editor = <Select required={frule.required} value={formItem[el]} id={inputid} name={inputid} fullWidth disabled={!frule.editable} onChange={(event) => {
                                                setformItem({ ...formItem, [el]: event.target.value });
                                            }}>
                                                <MenuItem value={undefined} disabled>--Pilih--</MenuItem>
                                                {Object.keys(frule.choices).map((it) => <MenuItem value={it} key={el + "-" + it}>{frule.choices[it]}</MenuItem>)}
                                            </Select>
                                        }
                                    } else if (frule.link) {
                                        let choices = adminStore.getItems(frule.link);
                                        console.log("Current val:", { [el]: formItem[el] })
                                        editor = <Select required={frule.required} value={formItem[el]?._id || formItem[el] || ""} id={inputid} name={inputid} fullWidth disabled={!frule.editable} onChange={(event) => {
                                            console.log("Updated to:", { [el]: event.target.value })
                                            setformItem({ ...formItem, [el]: event.target.value });
                                        }}>
                                            <MenuItem value={undefined} disabled>--Pilih--</MenuItem>
                                            {choices.map((itm) => <MenuItem value={itm._id} key={el + "-" + itm._id}>{representItemToText(itm, globalRule[frule.link])}</MenuItem>)}
                                        </Select>
                                    }
                                } else if (frule.type === "textarea:rich") {
                                    editor = <SunEditor required={frule.required} lang="id" setContents={formItem[el]} id={inputid} name={inputid} onChange={handleEditorUpdate(formItem, el)} />
                                } else if (frule.type === "textarea") {
                                    editor = <Input multiline rows={5} type={frule.type} required={frule.required} id={inputid} value={formItem[el]} name={inputid} fullWidth disabled={!frule.editable} onChange={(event) => {
                                        setformItem({ ...formItem, [el]: event.target.value });
                                    }} />
                                } else {
                                    editor = <Input type={frule.type} required={frule.required} id={inputid} value={formItem[el]} name={inputid} fullWidth disabled={!frule.editable} onChange={(event) => {
                                        setformItem({ ...formItem, [el]: event.target.value });
                                    }} />
                                }
                                return <Grid item container spacing={3} key={el} className={styles.fieldItem}>
                                    <Grid item md={4} lg={3}>
                                        <InputLabel htmlFor={inputid}>
                                            {frule.title}
                                        </InputLabel>
                                        <When condition={!!frule.required}>
                                            <Chip color="primary" size="small" icon={<ErrorOutline />} label="Wajib" />
                                        </When>
                                    </Grid>
                                    <Grid item md={8} lg={9}>
                                        {editor}
                                        <When condition={!!frule.helper}>
                                            <FormHelperText>{frule.helper}</FormHelperText>
                                        </When>
                                    </Grid>
                                </Grid>
                            })}
                        </Grid>
                    </Box>
                </Paper>

                {rule.extras?.editor?.map((id, idx) => {
                    if (Array.isArray(id) && id[0] === "notes") {
                        return <Box my={3} key={`${id[0]}-${idx}`}><Paper><Box padding={3}><Typography>{id[1].note}</Typography></Box></Paper></Box>
                    }

                    if (id === "images") {
                        return <Box my={3} key={`${id}-${idx}`}><Paper style={{ overflow: "hidden" }}><ImageBoxIndex rule={rule} globalRule={globalRule} itemId={match.params.id} images={formItem.images || []} /></Paper></Box>;
                    }
                    return <div key={`${id}-${idx}`}>Unsupported Extras: <Code>{JSON.stringify(id)}</Code></div>
                })}

                <Box mt={3}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}

export default observer(AdminManageEditor);
