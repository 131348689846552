import React from 'react'
import { makeStyles, Card, CardContent, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme=>({
    root: {
        backgroundColor: theme.palette.primary.light,
        backgroundPosition: "right",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        boxShadow: "7px 4px 20px rgba(53, 53, 53, 0.23)",
        position: "relative",
        textAlign: "left",
        display: "block",
        textDecoration: "none"
    },
    konten: { 
        zIndex: 5,
        display: "flex",
        flexDirection: "column",
        minHeight: 200,
        color: theme.palette.text.secondary,
        width: "70%",
        "& section.info": {
            flexGrow: 1,

            "& h3": {
                color: "black",
                fontWeight: 500
            }
        },
    }
}));

function PelayananCard({ title, children, jadwal, image, linkTo}) {
    const styles = useStyles();

    return (
        <Card className={styles.root} style={{ backgroundImage: `url(${image})` }}  component={Link} to={linkTo} raised>
            <CardContent className={styles.konten}>
                <section className="info">
                    <Typography variant="h6" component="h3">{title}</Typography>
                    <Typography variant="body1" component="div">{children}</Typography>
                </section>
                <section>
                    <Typography variant="h6" components="h4">Jadwal</Typography>
                    <Typography variant="body1" component="div">{jadwal}</Typography>
                </section>
            </CardContent>
        </Card>
    )
}

export default PelayananCard
