import { decorate, observable, action, computed } from "mobx";

import { axios } from "./_api";
export default class BeritaStore {
    error = {};

    _items = {};
    isLoading = true;

    hasFullRefresh = false;

    selectedItem = null;

    _baseItem = {
        title: "",
        description: "",
        images: [null],
        body: "",
        created_at: "",
        updated_at: "",
    };

    get items() {
        return Object
            .values(this._items)
            .filter(e => e.should_list)
            .sort((a, b) =>
                (b.updated_on.toString()).localeCompare((a.updated_on.toString()))
            );
    }

    get item() {
        return ({ ...this._baseItem, ...this._items[this.selectedItem] });
    }

    fetch() {
        this.isLoading = true;

        axios.get("manage/berita").then(data => {
            this.hasFullRefresh = true;
            if (data.data.status) {
                data.data.data.forEach(element => {
                    this._items[element._id] = element;
                });
            } else {
                // error happened.
                this.error = {
                    title: data.data.error.title,
                    description: data.data.error.description
                }
            }
            this.isLoading = false;

        }).catch(err => {
            console.log(err);
            this.isLoading = false;
            this.error = {
                title: "Terjadi masalah pada saat mengontak server. (HTTP)",
                description: "Mohon cek kembali koneksi anda. Jika masalah terus berlanjut, mohon hubungi developer."
            }
        });
    }

    clearSelection() {
        this.selectedItem = null;
    }

    fetchItem(id) {
        this.selectedItem = id;
        if (!this._items.hasOwnProperty(id) || !this._items[id] || !this._items[id].body) {
            axios.get("manage/berita/" + id).then(data => {
                if (data.data.status) {
                    this._items[data.data.data._id] = data.data.data;
                } else {
                    // error happened.
                    this.error = {
                        title: data.data.error.title,
                        description: data.data.error.description
                    }
                }
                this.isLoading = false;

            }).catch(err => {
                console.log(err);
                this.isLoading = false;
                this.error = {
                    title: "Terjadi masalah pada saat mengontak server. (HTTP)",
                    description: "Mohon cek kembali koneksi anda. Jika masalah terus berlanjut, mohon hubungi developer."
                }
            });
        }
    }
}

decorate(BeritaStore, {
    items: computed,
    selectedItem: observable,
    _items: observable,
    fetch: action,
    fetchItem: action,
    isLoading: observable
})