import React from 'react'
import { observer } from 'mobx-react';
import { makeStyles, Table, Paper, Box, Typography, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import Col from '../../grid-componoents/col';
import Row from '../../grid-componoents/row';


const useStyles = makeStyles(theme => ({
    paperOverview: {
        textAlign: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    numberGradient: {
        background: "linear-gradient(90deg, #A770EF 0%, #CF8BF3 50.52%, #FDB99B 100%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        color: "transparent",
        fontWeight: 600,
        fontSize: "5rem"
    },
    numberGradientOrange: {
        background: "radial-gradient(82.74% 82.74% at 91.01% 66.72%, #FF7E5F 0%, #FEB47B 100%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        color: "transparent",
        fontWeight: 600,
        fontSize: "2rem"
    },
    taroTengah: {
        "& th, & td": {
            textAlign: "center"
        },
    }
}));

function Staffs({ staffData = [], staffCompiledData = [] }) {
    const styles = useStyles();

    return (
        <div>
            <section name="overview">
                <Paper elevation={3} className={styles.paperOverview}>
                    <Box padding={3}>
                        <div className="container-fluid">
                            <Row>
                                <Col size={12}>
                                    <Typography>Jumlah Staff</Typography>
                                    <Typography className={styles.numberGradient}>{staffCompiledData[0]}</Typography>
                                </Col>
                            </Row>
                            <Row>
                                <Col size={6}>
                                    <Typography>
                                        ASN*
                                    </Typography>
                                    <Typography className={styles.numberGradientOrange}>
                                        {staffCompiledData[1]}
                                    </Typography>
                                </Col>
                                <Col size={6}>
                                    <Typography>
                                        Bukan ASN*
                                    </Typography>
                                    <Typography className={styles.numberGradientOrange}>
                                        {staffCompiledData[2]}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row>
                                <Col size={12}>
                                    <Box textAlign="left" paddingY={2}>
                                        <Typography><small>*) ASN = Aparatur Sipil Negara</small></Typography>
                                    </Box>
                                </Col>
                            </Row>
                        </div>
                    </Box>
                </Paper>
            </section>
            <section name="detailer">
                <Paper elevation={3}>
                    <Box padding={3} style={{ overflow: "auto" }}>
                        <Table className={styles.taroTengah}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Jenis Tenaga</TableCell>
                                    <TableCell>Jumlah</TableCell>
                                    <TableCell>ASN</TableCell>
                                    <TableCell>Bukan ASN</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {staffData.map((data, idx) => <TableRow key={idx}>
                                    <TableCell>{data.title}</TableCell>
                                    <TableCell>{data.asn_total + data.nonasn_total}</TableCell>
                                    <TableCell>{data.asn_total}</TableCell>
                                    <TableCell>{data.nonasn_total}</TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>
            </section>
        </div>
    )
}

export default observer(Staffs)
