import { AppBar, Button, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { AccountCircle, Menu } from "@material-ui/icons";
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAdminStore } from '../../store/use-store';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        zIndex: theme.zIndex.drawer + 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

function AdminNavbar({ title = "Admin Panel" }) {
    const classes = useStyles();
    const adminStore = useAdminStore();

    return (
        <AppBar position="fixed" className={classes.root}>
            <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <Menu />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
                <Button color="inherit" component={Link} to={"/admin/manage/user/" + adminStore.userProfile._id}>
                    <AccountCircle /> @{adminStore.userProfile.username}
                </Button>
            </Toolbar>
        </AppBar>
    )
}

export default observer(AdminNavbar)
