import React from 'react'
import { Typography, Box } from '@material-ui/core'

function AdminWelcome() {
    return (
        <Box marginY={4}>
            <Typography variant="h4">Admin Panel</Typography>
            <Typography>
                Selamat datang di admin panel. Silahkan pergunakan menu sebelah kiri untuk mengakses berbagai
                menu pada web ini.
            </Typography>
        </Box>
    )
}

export default AdminWelcome
